import React, {useEffect, useState} from 'react';
import {FiChevronRight, FiHome} from "react-icons/fi";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import cx from "classnames";
import {getAllMethods} from "../services/technicalService";

function Methods(props) {

  const {t} = useTranslation();

  const [methods, setMethods] = useState([]);

  const fetchAllMethods = async () => {
    await getAllMethods()
      .then((res)=>{
        setMethods(res.results);
      })
      .catch((err)=>{console.log(err)});
  }

  useEffect(() => {
    fetchAllMethods();
  }, []);

  return (
    <div>
      <div className="text-gray-500 flex items-center gap-2 mt-2 mb-6">
        <span className="text-xs font-semibold flex items-center">
          <FiHome size={12}/> <FiChevronRight size={12}/>
        </span>
        <h4 className="text-xs font-semibold text-gray-500">{t("METHODS.TITLE")}</h4>
      </div>
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-xl font-bold">{t("METHODS.TITLE")}</h1>
        {/*<Link to='/new-partner'*/}
        {/*      className="cursor-pointer bg-azure px-4 py-2 text-sm font-semibold rounded-lg text-white hover:opacity-60 hover:shadow">*/}
        {/*  {t("METHODS.ADD_BTN")}*/}
        {/*</Link>*/}
      </div>
      <div className='bg-white p-3 rounded-lg mt-4 shadow overflow-x-auto'>
        <table className="min-w-full border-collapse" id='my-applications-table'>
          <thead className="bg-gray-100 customShadow">
          <tr>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("METHODS.TABLE.METHOD_ID")}</th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("METHODS.TABLE.METHOD_NAME")}</th>
          </tr>
          </thead>
          <tbody className="text-gray-800 text-sm ">
          {
            methods.map((it) =>
              <tr key={it.methodId} className={cx('border')}>
                <td className="text-left py-3 px-2">{it.methodId}</td>
                <td className="text-left py-3 px-2">{it.methodName}</td>
              </tr>
            )
          }
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Methods;
