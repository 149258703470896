import React, {useEffect, useRef, useState} from 'react';
import {FiAlertCircle, FiCheckCircle, FiChevronRight, FiHome, FiXOctagon} from "react-icons/fi";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import DatePicker from "react-datepicker";
import cx from "classnames";

import { ReactComponent as NewClientSVG } from '../assets/newCustumor.svg';
import {useNavigate, useParams} from "react-router-dom";
import Modal from "./Modal";
import {addPartner, editPartner, getAllCompanies, showPartners} from "../services/partnerService";

function ClientCreatedModal({close, closeAndAdd}){

  const {t} = useTranslation();

  return(
    <div className='flex flex-col items-center'>
      <div className='min-w-[35vw] flex flex-col items-center justify-center gap-5'>
        <FiCheckCircle size={84} className='text-green-700 '/>
        <h1 className='text-center text-xl font-semibold mb-2'>{t("MASTER.CLIENTS.CLIENT_CREATED_MSG")}</h1>
      </div>
      <div className='w-full mt-6 flex items-center justify-between px-10'>
        <button onClick={() => close()}
                className='hover:text-azure hover:border-azure flex items-center gap-2 px-4 py-1.5 rounded-lg border text-sm font-semibold text-gray-600'>
          {t("GLOBAL.BACK")}
        </button>
        <button onClick={()=> closeAndAdd() }
                className='flex items-center gap-2 px-4 py-1.5 rounded-lg border border-azure bg-azure text-sm font-semibold text-white hover:shadow-lg'>
          {t("MASTER.CLIENTS.ADD_BTN")}
        </button>
      </div>
    </div>
  )
}

function EditPartner(props) {

  const {t} = useTranslation();
  const navigate = useNavigate();
  const params  = useParams();
  const {control, register, handleSubmit, reset, setValue, formState: {errors}} = useForm();

  const colorSelectRef = useRef(null);

  const [isColorSelectOpen, setIsColorSelectOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState("rgba(0, 127, 255, 1)");
  const [loading, setLoading] = useState(false);
  const [isClientCreated, setIsClientCreated] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const [isEmailValide, setIsEmailValide] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [companies, setCompanies] = useState([]);

  const closeClientCreatedModal = () => {
    setIsClientCreated(false);
    navigate(-1);
  }
  const closeAndAddClient = ()=>{
    setIsClientCreated(false);
    setSelectedColor("rgba(0, 127, 255, 1)");
    reset();
  }

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValide(regex.test(email));
  };

  const onSubmit = async (data) => {
    setLoading(true);
    setErrorCode("");

    const newPartnerData = {
      partnerId: params.id,
      partnerName: data.intitule,
      validity: data.endDate,
      companyId: data.companyId,
      isProvision: data.isProvision,
      isMTSMS: data.isMTSMS,
      isSubscribe: data.isSubscribe,
      isCharge: data.isCharge,
      status: data.status,
    }

    editPartner(newPartnerData)
      .then((res)=>{
        setIsClientCreated(true);
        setLoading(false);
        console.log(res);
      })
      .catch((err)=>{ console.log(err) });
  }

  const fetchAllCompanies = async ()=>{
    await getAllCompanies()
      .then((res)=> {
        setCompanies(res.results);
      })
      .catch((err)=>console.log(err));
  }

  useEffect(()=>{
    fetchAllCompanies();
  }, [])

  useEffect(() => {
    if(Object.keys(params).length > 0){
      showPartners(params.id)
        .then((res)=>{
          let data = res.results;
          console.log(data);
          setValue("intitule", data.partnerName);
          setValue("companyId", data.companyId);
          setValue("endDate", data.validity);

          setValue("isProvision", data.isProvision);
          setValue("isMTSMS", data.isMTSMS);
          setValue("isSubscribe", data.isSubscribe);
          setValue("isCharge", data.isCharge);
          setValue("status", data.status);

        })
        .catch((err)=>{console.log(err)})
    }
  },[params])

  const handleClickOutside = (event) => {
    if (colorSelectRef.current && !colorSelectRef.current.contains(event.target)) {
      setIsColorSelectOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div className="text-gray-500 flex items-center gap-2 mt-2 mb-6">
        <span className="text-xs font-semibold flex items-center">
          <FiHome size={12}/> <FiChevronRight size={12}/>
        </span>
        <h4 onClick={()=> navigate(-1) } className="text-xs font-semibold text-gray-500 cursor-pointer hover:underline hover:text-azure">
          {t("GLOBAL.DASHBOARD")}
        </h4>
        <span className="text-xs font-semibold flex items-center">
          <FiChevronRight size={12}/>
        </span>
        <h4 className="text-xs font-semibold text-gray-500">
          {t("PARTNERS.ADD_BTN")}
        </h4>
      </div>
      <div className="flex flex-col lg:flex-row gap-10" >
        <div className="lg:w-[60%] w-full">
          { errorCode.length > 0 && <p className='text-sm font-semibold text-[#D6323A] mb-5' >{t(`ERRORS.${errorCode}`)}</p>}
          <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col w-full'>
            <div className="bg-white p-2 pt-8 relative border rounded-lg mb-5">
              <p
                className="bg-white border px-2 py-1 text-xs font-semibold absolute top-[-12px] left-3 rounded-full">
                {t("PARTNERS.NEW_PARTNER.INFORMATION")}
              </p>
              <div className='flex flex-col col-span-2 mb-5'>
                <div className='px-2.5 mb-0.5 flex items-center justify-between'>
                  <label
                    className='text-xs text-gray-500 font-semibold'>{t("PARTNERS.TABLE.INTITULE")} *</label>
                  {errors.intitule &&
                    <label className='text-xs text-red-700 font-semibold'>{t("GLOBAL.REQUIRED_ERROR")}</label>}
                </div>
                <input
                  className='border rounded-lg px-2 py-1.5 text-sm'
                  placeholder={t("PARTNERS.TABLE.INTITULE")}
                  {...register("intitule", {required: true})}
                />
              </div>
              <div className="grid grid-cols-2 gap-3 mb-5">
                <div className='flex flex-col'>
                  <div className='px-2.5 mb-0.5 flex items-center justify-between'>
                    <label
                      className='text-xs text-gray-500 font-semibold'>{t("PARTNERS.TABLE.COMPANY_ID")} *</label>
                    {errors.companyId &&
                      <label className='text-xs text-red-700 font-semibold'>{t("GLOBAL.REQUIRED_ERROR")}</label>}
                  </div>
                  <select
                    id="companyId"
                    {...register('companyId', {required: true})}
                    className="appearance-none border text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2 py-1.5">
                    <option selected className="text-gray-400">Choose a company</option>
                    {
                      companies.map((company) =>
                        <option key={company.companyId} value={company.companyId}>{company.companyName}</option>
                      )
                    }
                  </select>
                </div>
                <div className='flex flex-col'>
                  <div className='flex items-center justify-between'>
                    <label
                      className='text-xs mb-0.5 ml-2.5 text-gray-500 font-semibold'>{t("PARTNERS.TABLE.VALIDITY")} *</label>
                    {errors.endDate && <label
                      className='text-xs text-red-700 font-semibold max-w-[50%] truncate'>{t("GLOBAL.REQUIRED_ERROR")}</label>}
                  </div>
                  <Controller
                    control={control}
                    name='endDate'
                    rules={{required: true}}
                    render={({field}) => (
                      <DatePicker
                        selected={field.value}
                        onChange={(date) => {
                          field.onChange(date);
                          setStartDate(date);
                        }}
                        isClearable
                        dateFormat="dd/MM/yyyy"
                        className='border rounded-lg px-2 py-1.5 text-sm w-full'
                        wrapperClassName='w-full'
                        placeholderText={t("PARTNERS.TABLE.VALIDITY")}
                        maxDate={endDate}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="flex justify-between gap-4 flex-wrap">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="isProvision"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2 "
                    {...register('isProvision')}
                  />
                  <label htmlFor="isProvision" className="ms-2 text-sm font-medium text-gray-900">
                    Provision
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="isMTSMS"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2 "
                    {...register('isMTSMS')}
                  />
                  <label htmlFor="isMTSMS" className="ms-2 text-sm font-medium text-gray-900">
                    MTSMS
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="isSubscribe"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2 "
                    {...register('isSubscribe')}
                  />
                  <label htmlFor="isSubscribe" className="ms-2 text-sm font-medium text-gray-900">
                    Subscribe
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="isCharge"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2 "
                    {...register('isCharge')}
                  />
                  <label htmlFor="isCharge" className="ms-2 text-sm font-medium text-gray-900">
                    Charge
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="status"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2 "
                    {...register('status')}
                  />
                  <label htmlFor="status" className="ms-2 text-sm font-medium text-gray-900">
                    Status
                  </label>
                </div>
              </div>
              {/*<div className='flex flex-col relative mb-5'>*/}
              {/*  <div className='px-2.5 mb-0.5 flex items-center justify-between'>*/}
              {/*    <label*/}
              {/*      className='text-xs text-gray-500 font-semibold'>{t("PARTNERS.TABLE.EMAIL")} *</label>*/}
              {/*    {errors.email ?*/}
              {/*      errors.email.type === "required"*/}
              {/*        ? <label className='text-xs text-red-700 font-semibold'>{t("GLOBAL.REQUIRED_ERROR")}</label>*/}
              {/*        : <label className='text-xs text-red-700 font-semibold'>{t("ERRORS.EMAIL_INVALID")}</label>*/}
              {/*      : null*/}
              {/*    }*/}
              {/*  </div>*/}
              {/*  <input*/}
              {/*    className='border rounded-lg px-2 py-1.5 text-sm pr-10'*/}
              {/*    placeholder={t("PARTNERS.TABLE.EMAIL")}*/}
              {/*    {...register("email", {required: true, pattern: /^\S+@\S+$/i})}*/}
              {/*    onChange={(e) => validateEmail(e.target.value)}*/}
              {/*  />*/}
              {/*  {*/}
              {/*    isEmailValide*/}
              {/*      ? <FiCheckCircle size={16} className='text-green-700 absolute right-4 top-[1.7rem]'/>*/}
              {/*      : <FiXOctagon size={16} className='text-gray-500 absolute right-4 top-[1.7rem]'/>*/}
              {/*  }*/}
              {/*</div>*/}
            </div>
            <div className="flex items-center justify-end">
              {
                !loading &&
                <button
                  className="cursor-pointer bg-azure px-4 py-2 text-sm font-semibold rounded-lg text-white hover:opacity-60 hover:shadow">
                  {t("GLOBAL.SAVE")}
                </button>
              }
              {
                loading &&
                <div className="relative border border-azure px-4 py-2 h-9 w-24 rounded-lg">
                  <div className="loader"/>
                </div>
              }
            </div>
          </form>
        </div>
        <div className="lg:w-[40%] w-full">
          <h1 className="text-5xl font-thin text-center">{t("MASTER.CLIENTS.NEW_CLIENT.NEW_TITLE")}</h1>
          <NewClientSVG height="100%" width="100%"/>
        </div>
      </div>
      <Modal isOpen={isClientCreated} close={closeClientCreatedModal}>
        <ClientCreatedModal close={closeClientCreatedModal} closeAndAdd={closeAndAddClient}/>
      </Modal>
    </div>
  );
}

export default EditPartner;
