import React, {useEffect, useState} from 'react';
import {FiChevronRight, FiHome} from "react-icons/fi";
import {useTranslation} from "react-i18next";
import {CustomDropdownFilterObject} from "../ressources/CustomComponents";
import moment from "moment/moment";
import {getAllPartners} from "../services/partnerService";
import {getServicesByPartner} from "../services/serviceManagementServices";
import DataTable from "react-data-table-component";

function PartnerServices(props) {

  const {t} = useTranslation();

  const [isPartnerSelectOpen, setIsPartnerSelectOpen] = useState(false);
  const [partner, setPartner] = useState({});
  const [partnerList, setPartnerList] = useState([]);
  const [revenueData, setRevenueData] = useState([]);
  const [loading, setLoading] = useState(false);

  const selectPartner = (val)=>{
    setPartner(val);
    setIsPartnerSelectOpen(false);
  }

  const fetchPartners = async () => {
    await getAllPartners()
      .then((res)=> {
        setPartnerList(res.results);
      })
      .catch((err)=>console.log(err));
  }

  const fetchServices = async () =>{
    setLoading(true);
    await getServicesByPartner(partner.partnerId || 0)
      .then((res)=> {
        console.log(res.results);
        setRevenueData(res.results);
      })
      .catch((err)=>console.log(err))
      .finally(()=>{
        setLoading(false);
      })
  }

  useEffect(()=>{
    fetchPartners();
  }, []);

  useEffect(() => {
    if(Object.keys(partner).length > 0){
      fetchServices();
    }
  }, [ partner]);


  const columns = [
    {
      name: 'Operator Name',
      selector: row => row.operatorName,
      sortable: true,
    },
    {
      name: 'Partner Name',
      selector: row => row.partnerName,
      sortable: true,
    },
    {
      name: 'Service Name',
      selector: row => row.serviceName,
      sortable: true,
    },
    {
      name: 'Short Code',
      selector: row => row.shortCode,
      sortable: true,
    },
    {
      name: 'Price',
      selector: row => row.price,
      sortable: true,
    }
  ];

  return (
    <div>
      <div>
        <div className="text-gray-500 flex items-center gap-2 mt-2 mb-6">
          <span className="text-xs font-semibold flex items-center">
            <FiHome size={12}/> <FiChevronRight size={12}/>
          </span>
          <h4 className="text-xs font-semibold text-gray-500">{t("PARTNER_SERVICES.TITLE")}</h4>
        </div>
        <div className="flex items-center justify-between mb-6">
          <div className="w-1/3 text-start">
            <h1 className="text-xl font-bold">{t("PARTNER_SERVICES.TITLE")}</h1>
          </div>
          <div className="w-1/4">
            <CustomDropdownFilterObject
              label="Partners"
              isOpen={isPartnerSelectOpen}
              setIsOpen={setIsPartnerSelectOpen}
              itemList={partnerList}
              selectedItem={partner}
              itemKey="partnerName"
              idKey="partnerId"
              selectItem={selectPartner}
            />
          </div>
        </div>
      </div>
      {
        loading
          ?
          <div className='bg-white p-0 rounded-lg mt-4 shadow overflow-x-auto'>
            Loading...
          </div>
          :
          <div className='bg-white p-0 rounded-lg mt-4 shadow overflow-x-auto relative z-0'>
            <DataTable
              columns={columns}
              data={revenueData}
              customStyles={{
                headCells: {
                  style: {
                    backgroundColor: '#f3f4f6',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    fontSize: '12px',
                  },
                },
                cells: {
                  style: {
                    padding: '16px',
                    textAlign: 'left',
                  },
                },
              }}
              highlightOnHover
              pagination
            />
          </div>
      }
    </div>
  );
}

export default PartnerServices;
