import React,{ createContext, useState, useEffect, useRef } from "react";

export const UserContext = createContext();

export const UserProvider = ({ children, isLogged }) => {

  const [user, setUser] = useState({firstName: "Admin", lastName: ""});

  useEffect(()=>{
  },[isLogged]);

  const values = {
    user
  }

  return (
    <UserContext.Provider value={values}>
      {children}
    </UserContext.Provider>
  );
};
