import React, {useState, useEffect} from 'react';
import {FiChevronRight, FiHome} from "react-icons/fi";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import cx from "classnames";
import moment from "moment";
import {getAllPublishers} from "../services/campaignServices";

function Publisher(props) {

  const {t} = useTranslation();

  const [publishers, setPublishers] = useState([]);

  const fetchPublishers = async () => {
    await getAllPublishers()
      .then((res)=> setPublishers(res.results) )
      .catch((err)=>{console.log(err)});
  }

  useEffect(() => {
    fetchPublishers();
  }, []);

  return (
    <div>
      <div className="text-gray-500 flex items-center gap-2 mt-2 mb-6">
        <span className="text-xs font-semibold flex items-center">
          <FiHome size={12}/> <FiChevronRight size={12}/>
        </span>
        <h4 className="text-xs font-semibold text-gray-500">{t("PUBLISHERS.TITLE")}</h4>
      </div>
      <div className="flex items-center justify-between mb-6">
        <h1 className="text-xl font-bold">{t("PUBLISHERS.TITLE")}</h1>
        {/*<Link to='/new-partner'*/}
        {/*      className="cursor-pointer bg-azure px-4 py-2 text-sm font-semibold rounded-lg text-white hover:opacity-60 hover:shadow">*/}
        {/*  {t("PUBLISHERS.ADD_BTN")}*/}
        {/*</Link>*/}
      </div>
      <div className='bg-white p-3 rounded-lg mt-4 shadow overflow-x-auto'>
        <table className="min-w-full border-collapse" id='my-applications-table'>
          <thead className="bg-gray-100 customShadow">
          <tr>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">
              {t("PUBLISHERS.TABLE.NAME")}
            </th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">
              {t("PUBLISHERS.TABLE.EMAIL")}
            </th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">
              {t("PUBLISHERS.TABLE.PUB_TOKEN")}
            </th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">
              {t("PUBLISHERS.TABLE.STATUS")}
            </th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">
              {t("PUBLISHERS.TABLE.DATE_IN")}
            </th>
          </tr>
          </thead>
          <tbody className="text-gray-800 text-sm ">
          {
            publishers.map((it) =>
              <tr key={it.publisherId} className={cx('border')}>
                <td className="text-left py-3 px-2">{it.publisherName}</td>
                <td className="text-left py-3 px-2">{it.email}</td>
                <td className="text-left py-3 px-2">{it.pubToken}</td>
                <td className="text-left py-3 px-2">{it.status === 1 ? "True" : "False"}</td>
                <td className="text-left py-3 px-2">{moment(it.dateIn).calendar()}</td>
              </tr>
            )
          }
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Publisher;
