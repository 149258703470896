import React, {useEffect, useState} from 'react';
import {FiChevronRight, FiHome} from "react-icons/fi";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import cx from "classnames";
import moment from "moment/moment";
import {getAllContentServices, getAllContentServicesByOperator} from "../services/contentManagementService";
import {CustomDropdownFilterObject} from "../ressources/CustomComponents";
import {getAllOperators} from "../services/technicalService";

function ContentServices(props) {

  const {t} = useTranslation();

  const [isOperatorSelectOpen, setIsOperatorSelectOpen] = useState(false);
  const [operators, setOperators] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState({});

  const [contentServicesData, setContentServicesData] = useState([]);

  const selectOperator = (val) => {
    if(val.operatorId === 0){
      setSelectedOperator({});
    }else{
      setSelectedOperator(val);
    }
    setIsOperatorSelectOpen(false);
  }

  const fetchContentServicesByOperator = async () => {
    await getAllContentServicesByOperator(selectedOperator.operatorId)
      .then((res)=>{
        setContentServicesData(res.results);
      })
      .catch((err)=>{console.log(err)});
  }

  const fetchContentServices = async () => {
    await getAllContentServices()
      .then((res)=>{
        setContentServicesData(res.results);
      })
      .catch((err)=>{console.log(err)});
  }

  const fetchOperators = async ()=>{
    await getAllOperators()
      .then((res)=> setOperators(res.results) )
      .catch((err)=>{console.log(err)});
  }

  useEffect(() => {
    if(Object.keys(selectedOperator).length > 0){
      fetchContentServicesByOperator();
    }else{
      fetchContentServices();
    }
  }, [selectedOperator]);

  useEffect(() => {
    fetchOperators();
  }, []);

  return (
    <div>
      <div className="text-gray-500 flex items-center gap-2 mt-2 mb-6">
        <span className="text-xs font-semibold flex items-center">
          <FiHome size={12}/> <FiChevronRight size={12}/>
        </span>
        <h4 className="text-xs font-semibold text-gray-500">{t("CONTENT_MANAGEMENT.TITLE")}</h4>
      </div>
      <div className="flex items-center justify-between mb-6">
        <div className="w-1/4">
          <CustomDropdownFilterObject
            label="Operators"
            isOpen={isOperatorSelectOpen}
            setIsOpen={setIsOperatorSelectOpen}
            itemList={[{operatorId: 0, operatorName: "See All"} ,...operators]}
            selectedItem={selectedOperator}
            itemKey="operatorName"
            idKey="operatorId"
            selectItem={selectOperator}
          />
        </div>
        {/*<h1 className="text-xl font-bold">{t("CONTENT_MANAGEMENT.TITLE")}</h1>*/}
        {/*<Link to='/new-partner'*/}
        {/*      className="cursor-pointer bg-azure px-4 py-2 text-sm font-semibold rounded-lg text-white hover:opacity-60 hover:shadow">*/}
        {/*  {t("CONTENT_MANAGEMENT.ADD_BTN")}*/}
        {/*</Link>*/}
      </div>
      <div className='bg-white p-3 rounded-lg mt-4 shadow overflow-x-auto'>
        <table className="min-w-full border-collapse" id='my-applications-table'>
          <thead className="bg-gray-100 customShadow">
          <tr>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">
              {t("CONTENT_MANAGEMENT.TABLE.SERVICE_NAME")}
            </th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">
              {t("CONTENT_MANAGEMENT.TABLE.PHYSICAL_PATH")}
            </th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">
              {t("CONTENT_MANAGEMENT.TABLE.VIRTUAL_PATH")}
            </th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">
              {t("CONTENT_MANAGEMENT.TABLE.CHANNEL_ID")}
            </th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">
              {t("CONTENT_MANAGEMENT.TABLE.OPERATOR_NAME")}
            </th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">
              {t("CONTENT_MANAGEMENT.TABLE.SHORT_CODE")}
            </th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">
              {t("CONTENT_MANAGEMENT.TABLE.SDP_SERVICE_ID")}
            </th>
          </tr>
          </thead>
          <tbody className="text-gray-800 text-sm">
          {
            contentServicesData.map((it) =>
              <tr key={it.serviceId} className={cx('border')}>
                <td className="text-left py-3 px-2">{it.serviceName}</td>
                <td className="text-left py-3 px-2">{it.subPhysicalPath}</td>
                <td className="text-left py-3 px-2">{it.subVirtualPath}</td>
                <td className="text-left py-3 px-2">{it.subChannelId}</td>
                <td className="text-left py-3 px-2">{it.operatorName}</td>
                <td className="text-left py-3 px-2">{it.shortCode}</td>
                <td className="text-left py-3 px-2">{it.sdpServiceID}</td>
              </tr>
            )
          }
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ContentServices;
