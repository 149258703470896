import api from "./api";

export const getAllOperators = async ()=>{
  try {
    const response = await api.get('/LocalApi/GetOperators');
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const getAllMethods = async ()=>{
  try {
    const response = await api.get('/LocalApi/GetMethods');
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const getAllOperatorsCredentials = async ()=>{
  try {
    const response = await api.get('/LocalApi/OperatorCredentials');
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const addOperatorCredential = async (data)=>{
  try {
    const response = await api.post('/LocalApi/AddOpCredentials', data);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}
