import "../styles/loading.css";
import React, {useEffect, useState} from 'react';
import cx from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import bg from '../assets/login-bg.jpg';
import bgV2 from '../assets/hero-bg.webp';
import { FiEye, FiEyeOff } from 'react-icons/fi';

import adminLogo from '../assets/AdminLTELogo.png';
import {loginEvent} from "../Context/events";

export default function Login() {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isEmailFocused, setIsEmailFocused] = useState(false);
  const [isPassFocused, setIsPassFocused] = useState(false);
  const [isPassHidden, setIsPassHidden] = useState(true);
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [errorCode, setErrorCode] = useState("");

  const submitLogin = async ()=>{

    if(email === "Admin" && pass === "Admin@123"){
      setLoading(true);
      setTimeout(()=>{
        localStorage.setItem('accessToken', "Admin");
        loginEvent.emit("login", true);
      }, 2000)
    }

    // if(email.length === 0 || pass.length === 0 ){
    //   setErrorCode("ALL_REQUIRED");
    //   return null
    // }
    // setLoading(true);
    //
    // const data = {
    //   username: email,
    //   password: pass
    // }
    //
    // login(data)
    // .then((res)=>{
    //   localStorage.setItem('accessToken', res.accessToken);
    //   if (res.refreshToken) {
    //     localStorage.setItem('refreshToken', res.refreshToken);
    //   }
    //   if (email.split('_')[0] === "master"){
    //     loginEvent.emit("master", true);
    //   }else{
    //     loginEvent.emit("master", false);
    //   }
    //   loginEvent.emit("login", true);
    //   setTimeout(() => {
    //     setLoading(false);
    //     navigate("/")
    //   }, 500);
    // }).catch((err)=>{
    //   setErrorCode(err.response.data.message);
    //   console.error('Error signing in:', err);
    //   setLoading(false);
    // });
  }

  useEffect(() => {
    const handleKeyPress = (event) => {
      // if (event.key === 'Enter') {
      //   submitLogin();
      // }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  return (
    <div className='flex select-none relative items-center justify-end p-4' >
      <div className='w-full h-[100vh] absolute top-0 left-0' >
        <img alt="bg" src={bg} className='h-full w-full object-cover absolute' />
        <div className='dark-overlay' />
        <div className='h-[90%] lg:flex hidden w-1/2 flex-col items-end justify-center p-8' >
          <h1 className='text-white relative z-20 font-semibold text-5xl mb-2' >Welcome in</h1>
          <h1 className='text-white relative z-20 font-bold text-6xl mb-5' >C-Portal</h1>
          <h2 className='text-white relative z-20 font-semibold' >Always good to see you again</h2>
        </div>
      </div>
      {
        loading ?
        <div className='w-1/2 h-[90vh] relative flex flex-col items-center justify-center' >
          <div className='loading' > <div className='box' /> </div>
        </div>
        :
        <div className='lg:w-1/2 w-full h-[90vh] mt-[calc(5vh_-_1rem)] bg-white flex flex-col items-center justify-center relative rounded-lg' >
          <div className="absolute top-5 left-0 flex items-center justify-between w-full px-20" >
            <div className="h-32 w-20" >
              <img src={adminLogo} alt="visio-bpm" className="w-full h-full object-contain" />
            </div>
            <div >
              <span className="text-2xl font-extrabold" >Taqniyat Tech</span>
            </div>
          </div>
          <h1 className='font-semibold text-3xl mb-20' >{t("LOGIN.LOGIN_TITLE")}</h1>
          { errorCode.length > 0 && <p className='text-sm font-semibold text-[#D6323A] mb-5' >{t(`ERRORS.${errorCode}`)}</p>}
          <div className={cx('flex flex-col lg:w-1/2 w-2/3 relative transition-all duration-300 mb-2')} >
            {
              email.length > 0 ?
              <label className={cx('transition-all duration-300 absolute text-xs top-2 text-gray-600 z-30' )} >
                {t("LOGIN.EMAIL")}
              </label>
              :
              <label className={cx('transition-all duration-300 absolute text-gray-600',{'text-xs top-2':(isEmailFocused), 'text-sm top-8': !isEmailFocused} )} >
                {t("LOGIN.EMAIL")}
              </label>
            }
            <input
              type='text'
              className={cx('border-b py-2 pt-8 text-sm w-full relative z-20 bg-transparent transition-all duration-300', {'border-azure':isEmailFocused})}
              onFocus={()=> setIsEmailFocused(true) }
              onBlur={()=> setIsEmailFocused(false) }
              onChange={(e)=> setEmail(e.target.value) }
              value={email}
            />
          </div>
          <div className='flex flex-col lg:w-1/2 w-2/3 relative' >
            {
              pass.length > 0 ?
              <label className={cx('transition-all duration-300 absolute text-xs top-2 text-gray-600 z-30' )} >
                {t("GLOBAL.PASSWORD")}
              </label>
              :
              <label className={cx('transition-all duration-300 absolute text-gray-600',{'text-xs top-2':(isPassFocused), 'text-sm top-8': !isPassFocused} )} >
                {t("GLOBAL.PASSWORD")}
              </label>
            }
            <input
              type={ isPassHidden ? "password" : "text"}
              className={cx('border-b py-2 pt-8 pr-10 text-sm w-full relative z-20 bg-transparent transition-all duration-300', {'border-azure':isPassFocused})}
              onFocus={()=> setIsPassFocused(true) }
              onBlur={()=> setIsPassFocused(false) }
              onChange={(e)=> setPass(e.target.value) }
              value={pass}
            />
            {
              isPassHidden
              ? <FiEye className='absolute top-8 right-2 z-30 cursor-pointer' onClick={()=> setIsPassHidden(false) } />
              : <FiEyeOff className='absolute top-8 right-2 z-30 cursor-pointer' onClick={()=> setIsPassHidden(true) } />
            }
          </div>
          <button onClick={()=> submitLogin() } className='bg-azure hover:bg-indigo text-white w-1/2 rounded-lg mt-10 py-2 font-semibold ' >
            {t("LOGIN.BUTTON")}
          </button>
        </div>
      }
    </div>
  )
}
