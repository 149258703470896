import logo from './logo.svg';
import './App.css';
import "react-datepicker/dist/react-datepicker.css";

import {Suspense, useEffect, useState} from "react";
import {AnimatePresence} from "framer-motion";
import {UserProvider} from "./Context/UserContext";
import {createBrowserRouter, Navigate, Outlet, RouterProvider} from "react-router-dom";
import Root from "./components/Root";
import Dashboard from "./components/Dashboard";
import Partners from "./components/Partners";
import Login from "./components/Login";
import {loginEvent} from "./Context/events";
import NewPartner from "./components/NewPartner";
import Services from "./components/Services";
import ServiceStatistics from "./components/ServiceStatistics";
import ContentManagement from "./components/ContentManagement";
import Campaigns from "./components/Campaigns";
import CampaignReports from "./components/CampaignReports";
import Operator from "./components/Operator";
import Methods from "./components/Methods";
import Company from "./components/Company";
import Publisher from "./components/Publisher";
import OperatorCredentials from "./components/OperatorCredentials";
import EditPartner from "./components/EditPartner";
import CampaignStatistics from "./components/CampaignStatistics";
import PartnerServices from "./components/PartnerServices";
import PartnerRevenue from "./components/PartnerRevenue";
import RegisteredEmailContacts from "./components/RegisteredEmailContacts";
import Mobily from "./components/OperatorReports/Mobily";
import Zain from "./components/OperatorReports/Zain";
import ContentServices from "./components/ContentServices";

const router = (isLogged, isMaster) => createBrowserRouter([
  {
    path: "/",
    element: isLogged ? <Root/> : <Navigate to="/login" />,
    children: [
      {
        path: "/",
        element: <Dashboard/>
      },{
        path: "/partners",
        element: <Partners/>
      }, {
        path: "/new-partner",
        element: <NewPartner/>
      }, {
        path: "/edit-partner/:id",
        element: <EditPartner/>
      }, {
        path: "/partners-services",
        element: <PartnerServices/>
      }, {
        path: "/partners-revenue",
        element: <PartnerRevenue/>
      }, {
        path: '/services',
        element: <Services/>
      }, {
        path: '/service-statistics',
        element: <ServiceStatistics/>
      }, {
        path: '/content-management',
        element: <ContentManagement/>
      }, {
        path: '/content-services',
        element: <ContentServices/>
      }, {
        path: '/campaigns',
        element: <Campaigns/>
      }, {
        path: '/campaign-reports',
        element: <CampaignReports/>
      }, {
        path: '/publisher',
        element: <Publisher/>
      }, {
        path: '/campaign-statistics',
        element: <CampaignStatistics/>
      }, {
        path: '/registered-emails',
        element: <RegisteredEmailContacts/>
      }, {
        path: '/operator',
        element: <Operator/>
      }, {
        path: '/methods',
        element: <Methods/>
      }, {
        path: '/operator-credentials',
        element: <OperatorCredentials/>
      }, {
        path: '/company',
        element: <Company/>
      }, {
        path: '/publisher',
        element: <Publisher/>
      }, {
        path: 'operatorReports',
        element: <Outlet />,
        children: [
          {
            path: 'mobily',
            element: <Mobily />
          },
          {
            path: 'zain',
            element: <Zain />
          }
        ]
      }
    ]
  },{
    path: "/login",
    element: isLogged ? <Navigate to="/" /> : <Login/>,
  }
]);

function App() {

  const [isLogged, setIsLogged] = useState(null);

  const [lastActivity, setLastActivity] = useState(Date.now());

  const logout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    loginEvent.emit('login', false);
  };

  useEffect(() => {
    const resetTimer = () => {
      setLastActivity(Date.now());
    };

    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keydown', resetTimer);
    window.addEventListener('scroll', resetTimer);

    const interval = setInterval(() => {
      if (Date.now() - lastActivity > 600000) {
        logout();
      }
    }, 60000);

    return () => {
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keydown', resetTimer);
      window.removeEventListener('scroll', resetTimer);
      clearInterval(interval);
    };
  }, [lastActivity]);

  const isUserLogged = ()=>{
    if (localStorage.getItem('accessToken')) {
      setIsLogged(true)
    } else {
      setIsLogged(false);
    }
  }

  useEffect(() => {
    const handleLoginEvent = (state) => {
      setIsLogged(state);
    };

    loginEvent.on('login', handleLoginEvent);

    return () => {
      loginEvent.removeListener('login', handleLoginEvent);
    };
  }, [loginEvent]);

  useEffect(()=>{
    isUserLogged();
  },[]);

  if(isLogged === null){
    return (
      <Suspense fallback="loading">
      </Suspense>
    )
  }

  return (
    <Suspense fallback="loading">
      <AnimatePresence>
        <div className="App" >
          <UserProvider isLogged={isLogged} >
            <RouterProvider router={router(isLogged)} />
          </UserProvider>
        </div>
      </AnimatePresence>
    </Suspense>
  );
}

export default App;
