import api from "./api";

export const uploadMobilyRevenueReport = async (data)=>{
  try {
    const response = await api.post('/LocalApi/UploadMobilyRevenueReport', data);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}


export const uploadZainSubscriptionReport = async (data)=>{
  try {
    const response = await api.post('/LocalApi/UploadZainSubscriptionReport', data);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const uploadZainRevenueReport = async (data)=>{
  try {
    const response = await api.post('/LocalApi/UploadZainRevenueReport', data);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}
