import axios from "axios";

export const createApi = async () => {
  try {
    const configResponse = await fetch('/config.json');
    const config = await configResponse.json();

    const api = axios.create({ baseURL: config.apiUrl });
    return api;
  } catch (error) {
    console.error('Error fetching API configuration:', error);
    return axios.create();
  }
};

const api = await createApi();

export default api;
