import React, {useEffect, useState} from 'react';
import {FiChevronDown, FiChevronRight, FiChevronUp, FiEdit, FiHome} from "react-icons/fi";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import cx from "classnames";
import moment from "moment/moment";
import {addOperatorCredential, getAllOperators, getAllOperatorsCredentials} from "../services/technicalService";
import Modal from "./Modal";
import {CustomDropdownObject} from "../ressources/CustomComponents";

function NewCredentialModal({close}) {

  const {t} = useTranslation();

  const [loading, setLoading] = useState(false);
  const [operator, setOperator] = useState({});
  const [isOperatorSelectOpen, setIsOperatorSelectOpen] = useState(false);
  const [operatorsList, setOperatorsList] = useState([]);
  const [credentialDesc, setCredentialDesc] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [apiKey, setApiKey] = useState("");

  const selectOperator = (operator) => {
    setOperator(operator);
    setIsOperatorSelectOpen(false);
  }

  const save = async ()=>{
    setLoading(true);
    const credentialData = {
      "operatorId": operator.operatorId,
      "operatorName": operator.operatorName,
      "opCrDescription": credentialDesc,
      "userName": userName,
      "password": password,
      "opApiKey": apiKey
    };
    await addOperatorCredential(credentialData)
      .then((res)=>{
        close();
      })
      .catch(err=>{
        console.log(err);
      })
      .finally(()=>setLoading(false));
  }

  const fetchOperators = async () => {
    await getAllOperators()
    .then((res)=>{
      setOperatorsList(res.results);
    })
      .catch((err)=>{console.log(err)});
  }

  useEffect(() => {
    fetchOperators();
  }, []);

  return (
    <div>
      <div className="grid grid-cols-2 gap-3 mb-4">
        <CustomDropdownObject
          label={t("OPERATOR_CREDENTIALS.TABLE.OPERATOR_NAME")}
          isOpen={isOperatorSelectOpen}
          setIsOpen={setIsOperatorSelectOpen}
          itemList={operatorsList}
          selectedItem={operator}
          itemKey="operatorName"
          idKey="operatorId"
          selectItem={selectOperator}
        />
        {/*<div className="flex flex-col relative items-start">*/}
        {/*  <label*/}
        {/*    className='text-xs mb-0.5 ml-3 text-gray-500 font-semibold'>{t("OPERATOR_CREDENTIALS.TABLE.OPERATOR_NAME")} *</label>*/}
        {/*  <button*/}
        {/*    onClick={() => setIsOperatorSelectOpen(!isOperatorSelectOpen)}*/}
        {/*    className='relative border rounded-lg text-start px-2 py-1.5 text-sm font-semibold w-full'>*/}
        {/*    {*/}
        {/*      Object.keys(operator).length > 0*/}
        {/*        ? <span>{operator.operatorName}</span>*/}
        {/*        : <span className="text-gray-400">{t("OPERATOR_CREDENTIALS.TABLE.OPERATOR_NAME")}</span>*/}
        {/*    }*/}
        {/*    {*/}
        {/*      isOperatorSelectOpen*/}
        {/*        ? <FiChevronUp className="text-gray-400 absolute right-3 top-2"/>*/}
        {/*        : <FiChevronDown className="text-gray-400 absolute right-3 top-2"/>*/}
        {/*    }*/}
        {/*  </button>*/}
        {/*  {*/}
        {/*    isOperatorSelectOpen &&*/}
        {/*    <ul*/}
        {/*      className="bg-white text-start absolute top-14 left-0 w-full border shadow rounded-lg p-1 text-sm font-semibold">*/}
        {/*      {*/}
        {/*        operatorsList.map((op) =>*/}
        {/*          <li*/}
        {/*            key={op.operatorId}*/}
        {/*            className={cx("py-1.5 px-2 hover:bg-sky-100 rounded-lg cursor-pointer", {'text-sky-600 bg-sky-100': op.operatorId === operator.operatorId})}*/}
        {/*            onClick={() => selectOperator(op)}*/}
        {/*          >*/}
        {/*            {op.operatorName}*/}
        {/*          </li>*/}
        {/*        )*/}
        {/*      }*/}
        {/*    </ul>*/}
        {/*  }*/}
        {/*</div>*/}
        <div>
          <div className='px-2.5 mb-0.5 flex items-center justify-between'>
            <label
              className='text-xs text-gray-500 font-semibold'>{t("OPERATOR_CREDENTIALS.TABLE.OPERATOR_CR_Description")} *</label>
          </div>
          <input
            className='border rounded-lg px-2 py-1.5 text-sm w-full'
            placeholder={t("OPERATOR_CREDENTIALS.TABLE.OPERATOR_CR_Description")}
            onChange={(e) => setCredentialDesc(e.target.value)}
            value={credentialDesc}
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-3 mb-4">
        <div>
          <div className='px-2.5 mb-0.5 flex items-center justify-between'>
            <label
              className='text-xs text-gray-500 font-semibold'>{t("OPERATOR_CREDENTIALS.TABLE.USERNAME")} *</label>
          </div>
          <input
            className='border rounded-lg px-2 py-1.5 text-sm w-full'
            placeholder={t("OPERATOR_CREDENTIALS.TABLE.USERNAME")}
            onChange={(e) => setUserName(e.target.value)}
            value={userName}
          />
        </div>
        <div>
          <div className='px-2.5 mb-0.5 flex items-center justify-between'>
            <label
              className='text-xs text-gray-500 font-semibold'>{t("OPERATOR_CREDENTIALS.TABLE.PASSWORD")} *</label>
          </div>
          <input
            className='border rounded-lg px-2 py-1.5 text-sm w-full'
            placeholder={t("OPERATOR_CREDENTIALS.TABLE.PASSWORD")}
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
        </div>
      </div>
      <div className="mb-4" >
        <div className='px-2.5 mb-0.5 flex items-center justify-between'>
          <label
            className='text-xs text-gray-500 font-semibold'>{t("OPERATOR_CREDENTIALS.TABLE.OPERATOR_APIKEY")} *</label>
        </div>
        <input
          className='border rounded-lg px-2 py-1.5 text-sm w-full'
          placeholder={t("OPERATOR_CREDENTIALS.TABLE.OPERATOR_APIKEY")}
          onChange={(e) => setApiKey(e.target.value)}
          value={apiKey}
        />
      </div>

      <div className="flex items-center justify-end" >
        <button disabled={loading} onClick={()=> save() } className="bg-azure px-4 py-2 text-sm font-semibold rounded-lg text-white hover:opacity-60 hover:shadow" >
          {
            loading
              ? "Loading ..."
              : t("GLOBAL.SAVE")
          }
        </button>
      </div>
    </div>
  );
}

function OperatorCredentials(props) {

  const {t} = useTranslation();

  const [operators, setOperators] = useState([]);
  const [isNewCredentialModalOpen, setIsNewCredentialModalOpen] = useState(false);

  const openNewCredentialModal = () => {
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsNewCredentialModalOpen(true);
  }
  const closeNewCredentialModal = () => {
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsNewCredentialModalOpen(false);
    fetchOperators();
  }

  const fetchOperators = async () => {
    await getAllOperatorsCredentials()
      .then((res)=>{
        setOperators(res.results);
      })
      .catch((err)=>{console.log(err)});
  }

  useEffect(() => {
    fetchOperators();
  }, []);

  return (
    <div>
      <div className="text-gray-500 flex items-center gap-2 mt-2 mb-6">
        <span className="text-xs font-semibold flex items-center">
          <FiHome size={12}/> <FiChevronRight size={12}/>
        </span>
        <h4 className="text-xs font-semibold text-gray-500">{t("OPERATOR_CREDENTIALS.TITLE")}</h4>
      </div>
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-xl font-bold">{t("OPERATOR_CREDENTIALS.TITLE")}</h1>
        <button
          onClick={openNewCredentialModal}
          className="cursor-pointer bg-azure px-4 py-2 text-sm font-semibold rounded-lg text-white hover:opacity-60 hover:shadow">
          {t("OPERATOR_CREDENTIALS.ADD_BTN")}
        </button>
      </div>
      <div className='bg-white p-3 rounded-lg mt-4 shadow overflow-x-auto'>
        <table className="min-w-full border-collapse" id='my-applications-table'>
          <thead className="bg-gray-100 customShadow">
          <tr>
            <th
              className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("OPERATOR_CREDENTIALS.TABLE.OPERATOR_NAME")}</th>
            <th
              className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("OPERATOR_CREDENTIALS.TABLE.OPERATOR_CR_Description")}</th>
            <th
              className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("OPERATOR_CREDENTIALS.TABLE.USERNAME")}</th>
            <th
              className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("OPERATOR_CREDENTIALS.TABLE.PASSWORD")}</th>
            <th
              className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("OPERATOR_CREDENTIALS.TABLE.OPERATOR_APIKEY")}</th>
          </tr>
          </thead>
          <tbody className="text-gray-800 text-sm ">
          {
            operators.map((it) =>
              <tr key={it.operatorId} className={cx('border')}>
                <td className="text-left py-3 px-2">{it.operatorName}</td>
                <td className="text-left py-3 px-2">{it.opCrDescription}</td>
                <td className="text-left py-3 px-2">{it.userName}</td>
                <td className="text-left py-3 px-2">{it.password}</td>
                <td className="text-left py-3 px-2">{it.opApiKey}</td>
              </tr>
            )
          }
          </tbody>
        </table>
      </div>

      <Modal isOpen={isNewCredentialModalOpen} close={closeNewCredentialModal} title={t("OPERATOR_CREDENTIALS.TITLE")} >
        <NewCredentialModal close={closeNewCredentialModal} />
      </Modal>
    </div>
  );
}

export default OperatorCredentials;
