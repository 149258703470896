import React, {useCallback, useEffect, useState} from 'react';
import {FiChevronRight, FiHome} from "react-icons/fi";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {addService, getAllServices} from "../services/serviceManagementServices";
import cx from "classnames";
import Modal from "./Modal";
import {CustomDropdownObject} from "../ressources/CustomComponents";
import {getAllPartners} from "../services/partnerService";
import {getAllOperators, getAllOperatorsCredentials} from "../services/technicalService";
import {useForm} from "react-hook-form";

function NewServiceModal({close}) {

  const {t} = useTranslation();
  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();

  const [isPartnersOpen, setIsPartnersOpen] = useState(false);
  const [partner, setPartner] = useState({});
  const [partnersList, setPartnersList] = useState([]);
  const [isOperatorOpen, setIsOperatorOpen] = useState(false);
  const [operator, setOperator] = useState({});
  const [operatorsList, setOperatorsList] = useState([]);
  const [opCredentials, setOpCredentials] = useState([]);
  const [isSmsCredentialOpen, setIsSmsCredentialOpen] = useState(false);
  const [smsCredential, setSmsCredential] = useState({});
  const [isBillCredentialOpen, setIsBillCredentialOpen] = useState(false);
  const [billCredential, setBillCredential] = useState({});

  const [isSub, setIsSub] = useState(true);
  const [isBilling, setIsBilling] = useState(true);
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    if(Object.keys(partner).length > 0 ){
      data.partnerId = partner.partnerId;
      // data.operatorId = operator.operatorId;
      data.isSub = isSub;
      data.isBilling = isBilling;
      data.status = status;

      data.smsCredId = smsCredential.opCredId;
      data.billCredId = billCredential.opCredId;

      await addService(data)
        .then((res)=>{
          close();
          console.log(res);
        })
        .catch((err)=>console.log(err));
    }
  }

  const selectPartner = (val)=>{
    setPartner(val);
    setIsPartnersOpen(false);
  }
  const selectOperator = (val)=>{
    setOperator(val);
    setIsOperatorOpen(false);
  }
  const clickSmsCredential = (data)=>{
    setIsSmsCredentialOpen(false);
    setSmsCredential(data);
  }
  const clickBillCredential = (data)=>{
    setIsBillCredentialOpen(false);
    setBillCredential(data);
  }

  const fetchPartners = async ()=>{
    await getAllPartners()
      .then((res)=> setPartnersList(res.results) )
      .catch((err)=>{console.log(err)});
  }
  const fetchOperators = async ()=>{
    await getAllOperators()
      .then((res)=> setOperatorsList(res.results) )
      .catch((err)=>{console.log(err)});
  }
  const fetchOperatorsCredentials = async ()=>{
    await getAllOperatorsCredentials()
      .then((res)=> setOpCredentials(res.results) )
      .catch((err)=>console.log(err));
  }

  useEffect(() => {
    fetchPartners();
    fetchOperators();
    fetchOperatorsCredentials();
  }, []);

  return(
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-4">
        <div className='px-2.5 mb-0.5 flex items-center justify-between'>
          <label
            className='text-xs text-gray-500 font-semibold'>{t("SERVICES.TABLE.SERVICE_NAME")} *</label>
        </div>
        <input
          className='border rounded-lg px-2 py-1.5 text-sm w-full'
          placeholder={t("SERVICES.TABLE.SERVICE_NAME")}
          {...register("serviceName", {required: true})}
        />
      </div>
      <div className="grid grid-cols-3 gap-3 mb-4">
        <div>
          <div className='px-2.5 mb-0.5 flex items-center justify-between'>
            <label
              className='text-xs text-gray-500 font-semibold'>{t("SERVICES.TABLE.OP_SERVICE_ID")} *</label>
          </div>
          <input
            className='border rounded-lg px-2 py-1.5 text-sm w-full'
            placeholder={t("SERVICES.TABLE.OP_SERVICE_ID")}
            {...register("operatorServiceId", {required: true})}
          />
        </div>
        <div>
          <div className='px-2.5 mb-0.5 flex items-center justify-between'>
            <label
              className='text-xs text-gray-500 font-semibold'>{t("SERVICES.TABLE.LEGACY_SERVICE_ID")} *</label>
          </div>
          <input
            className='border rounded-lg px-2 py-1.5 text-sm w-full'
            placeholder={t("SERVICES.TABLE.LEGACY_SERVICE_ID")}
            {...register("legacyServiceId", {required: true})}
          />
        </div>
        <div>
          <div className='px-2.5 mb-0.5 flex items-center justify-between'>
            <label
              className='text-xs text-gray-500 font-semibold'>{t("SERVICES.TABLE.LEGACY_PRODUCT_ID")} *</label>
          </div>
          <input
            className='border rounded-lg px-2 py-1.5 text-sm w-full'
            placeholder={t("SERVICES.TABLE.LEGACY_PRODUCT_ID")}
            {...register("legacyProductId", {required: true})}
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-3 mb-4">
        <CustomDropdownObject
          label={t("SERVICES.TABLE.PARTNER_NAME")}
          isOpen={isPartnersOpen}
          setIsOpen={setIsPartnersOpen}
          itemList={partnersList}
          selectedItem={partner}
          itemKey="partnerName"
          idKey="partnerId"
          selectItem={selectPartner}
        />
        <div>
          <div className='px-2.5 mb-0.5 flex items-center justify-between'>
            <label
              className='text-xs text-gray-500 font-semibold'>{t("SERVICES.TABLE.OPERATOR_ID")} *</label>
          </div>
          <input
            type="number"
            className='border rounded-lg px-2 py-1.5 text-sm w-full'
            placeholder={t("SERVICES.TABLE.OPERATOR_ID")}
            {...register("operatorId", {required: true})}
          />
        </div>
        {/*<CustomDropdownObject*/}
        {/*  label={t("SERVICES.TABLE.OPERATOR_NAME")}*/}
        {/*  isOpen={isOperatorOpen}*/}
        {/*  setIsOpen={setIsOperatorOpen}*/}
        {/*  itemList={operatorsList}*/}
        {/*  selectedItem={operator}*/}
        {/*  itemKey="operatorName"*/}
        {/*  idKey="operatorId"*/}
        {/*  selectItem={selectOperator}*/}
        {/*/>*/}
      </div>
      <div className="grid grid-cols-3 gap-3 mb-4">
        <div>
          <div className='px-2.5 mb-0.5 flex items-center justify-between'>
            <label
              className='text-xs text-gray-500 font-semibold'>{t("SERVICES.TABLE.LOCAL_ID")} *</label>
          </div>
          <input
            className='border rounded-lg px-2 py-1.5 text-sm w-full'
            placeholder={t("SERVICES.TABLE.LOCAL_ID")}
            {...register("localId", {required: true})}
          />
        </div>
        <div>
          <div className='px-2.5 mb-0.5 flex items-center justify-between'>
            <label
              className='text-xs text-gray-500 font-semibold'>{t("SERVICES.TABLE.SHORT_CODE")} *</label>
          </div>
          <input
            className='border rounded-lg px-2 py-1.5 text-sm w-full'
            placeholder={t("SERVICES.TABLE.SHORT_CODE")}
            {...register("shortCode", {required: true})}
          />
        </div>
        <div>
          <div className='px-2.5 mb-0.5 flex items-center justify-between'>
            <label
              className='text-xs text-gray-500 font-semibold'>{t("SERVICES.TABLE.SENDER_ID")} *</label>
          </div>
          <input
            className='border rounded-lg px-2 py-1.5 text-sm w-full'
            placeholder={t("SERVICES.TABLE.SENDER_ID")}
            {...register("senderId", {required: true})}
          />
        </div>
      </div>
      <div className="grid grid-cols-3 gap-3 mb-4">
        <div>
          <div className='px-2.5 mb-0.5 flex items-center justify-between'>
            <label
              className='text-xs text-gray-500 font-semibold'>{t("SERVICES.TABLE.PRICE")} *</label>
          </div>
          <input
            className='border rounded-lg px-2 py-1.5 text-sm w-full'
            placeholder={t("SERVICES.TABLE.PRICE")}
            {...register("price", {required: true})}
          />
        </div>
        <div>
          <div className='px-2.5 mb-0.5 flex items-center justify-between'>
            <label
              className='text-xs text-gray-500 font-semibold'>{t("SERVICES.TABLE.PERIOD")} *</label>
          </div>
          <input
            className='border rounded-lg px-2 py-1.5 text-sm w-full'
            placeholder={t("SERVICES.TABLE.PERIOD")}
            {...register("period", {required: true})}
          />
        </div>
        <div>
          <div className='px-2.5 mb-0.5 flex items-center justify-between'>
            <label
              className='text-xs text-gray-500 font-semibold'>{t("SERVICES.TABLE.FREE_PERIOD")} *</label>
          </div>
          <input
            className='border rounded-lg px-2 py-1.5 text-sm w-full'
            placeholder={t("SERVICES.TABLE.FREE_PERIOD")}
            {...register("freePeriod", {required: true})}
          />
        </div>
      </div>
      <div className="grid grid-cols-3 gap-3 mb-4">
        <label className="inline-flex items-center cursor-pointer">
          <input type="checkbox" value="" className="sr-only peer" checked={isSub}
                 onChange={(e) => setIsSub(e.target.checked)}/>
          <div
            className="relative z-0 w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
          <span className="ms-3 text-sm font-medium text-gray-900">{t("SERVICES.TABLE.IS_SUB")}</span>
        </label>
        <label className="inline-flex items-center cursor-pointer">
          <input type="checkbox" value="" className="sr-only peer" checked={isBilling}
                 onChange={(e) => setIsBilling(e.target.checked)}/>
          <div
            className="relative z-0 w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
          <span className="ms-3 text-sm font-medium text-gray-900">{t("SERVICES.TABLE.IS_BILLING")}</span>
        </label>
        <label className="inline-flex items-center cursor-pointer">
          <input type="checkbox" value="" className="sr-only peer" checked={status}
                 onChange={(e) => setStatus(e.target.checked)}/>
          <div
            className="relative z-0 w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
          <span className="ms-3 text-sm font-medium text-gray-900">{t("SERVICES.TABLE.STATUS")}</span>
        </label>
      </div>
      <div className="grid grid-cols-2 gap-3 mb-4">
        <CustomDropdownObject
          label={t("SERVICES.TABLE.SMS_CRED_ID")}
          isOpen={isSmsCredentialOpen}
          setIsOpen={setIsSmsCredentialOpen}
          itemList={opCredentials}
          selectedItem={smsCredential}
          itemKey="opCrDescription"
          idKey="opCredId"
          selectItem={clickSmsCredential}
        />
        <CustomDropdownObject
          label={t("SERVICES.TABLE.BILL_CRED_ID")}
          isOpen={isBillCredentialOpen}
          setIsOpen={setIsBillCredentialOpen}
          itemList={opCredentials}
          selectedItem={billCredential}
          itemKey="opCrDescription"
          idKey="opCredId"
          selectItem={clickBillCredential}
        />
        {/*<div>*/}
        {/*  <div className='px-2.5 mb-0.5 flex items-center justify-between'>*/}
        {/*    <label*/}
        {/*      className='text-xs text-gray-500 font-semibold'>{t("SERVICES.TABLE.SMS_CRED_ID")} *</label>*/}
        {/*  </div>*/}
        {/*  <input*/}
        {/*    className='border rounded-lg px-2 py-1.5 text-sm w-full'*/}
        {/*    placeholder={t("SERVICES.TABLE.SMS_CRED_ID")}*/}
        {/*    {...register("smsCredId", {required: false})}*/}
        {/*  />*/}
        {/*</div>*/}
        {/*<div>*/}
        {/*  <div className='px-2.5 mb-0.5 flex items-center justify-between'>*/}
        {/*    <label*/}
        {/*      className='text-xs text-gray-500 font-semibold'>{t("SERVICES.TABLE.BILL_CRED_ID")} *</label>*/}
        {/*  </div>*/}
        {/*  <input*/}
        {/*    className='border rounded-lg px-2 py-1.5 text-sm w-full'*/}
        {/*    placeholder={t("SERVICES.TABLE.BILL_CRED_ID")}*/}
        {/*    {...register("billCredId", {required: false})}*/}
        {/*  />*/}
        {/*</div>*/}
      </div>
      <div className='flex justify-end mt-6'>

        {
          loading ?
            <div className='border border-azure rounded-lg h-9 w-32 relative'>
              <div className='loader'/>
            </div>
            :
            <input
              type="submit"
              value={t("GLOBAL.SAVE")}
              className='border border-azure font-semibold cursor-pointer rounded-lg px-4 py-1.5 text-sm text-azure hover:text-white hover:bg-azure'
            />
        }
      </div>
    </form>
  );
}

function Services(props) {

  const {t} = useTranslation();

  const [services, setServices] = useState([]);
  const [isNewServiceModalOpen, setIsNewServiceModalOpen] = useState(false);

  const openNewServiceModal = () => {
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsNewServiceModalOpen(true);
  }
  const closeNewServiceModal = () => {
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsNewServiceModalOpen(false);
    fetchAllServices();
  }

  const fetchAllServices = async () => {
    await getAllServices()
      .then((res) => setServices(res.results))
      .catch((err) => {
        console.log(err)
      });
  }

  useEffect(() => {
    fetchAllServices();
  }, []);

  return (
    <div>
      <div className="text-gray-500 flex items-center gap-2 mt-2 mb-6">
        <span className="text-xs font-semibold flex items-center">
          <FiHome size={12}/> <FiChevronRight size={12}/>
        </span>
        <h4 className="text-xs font-semibold text-gray-500">{t("SERVICES.TITLE")}</h4>
      </div>
      <div className="flex items-center justify-between mb-6">
        <h1 className="text-xl font-bold">{t("SERVICES.TITLE")}</h1>
        <button onClick={() => openNewServiceModal()}
                className="cursor-pointer bg-azure px-4 py-2 text-sm font-semibold rounded-lg text-white hover:opacity-60 hover:shadow">
          {t("SERVICES.ADD_BTN")}
        </button>
      </div>
      <div className='bg-white p-0 rounded-lg mt-4 shadow overflow-x-auto'>
        <table className="min-w-full border-collapse" id='my-applications-table'>
          <thead className="bg-gray-100 customShadow">
          <tr>
            {/*<th className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("SERVICES.TABLE.SERVICE_ID")}</th>*/}
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("SERVICES.TABLE.SERVICE_NAME")}</th>
            {/*<th className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("SERVICES.TABLE.OP_SERVICE_ID")}</th>*/}
            {/*<th className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("SERVICES.TABLE.LEGACY_SERVICE_ID")}</th>*/}
            {/*<th className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("SERVICES.TABLE.LEGACY_PRODUCT_ID")}</th>*/}
            {/*<th className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("SERVICES.TABLE.PARTNER_ID")}</th>*/}
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("SERVICES.TABLE.PARTNER_NAME")}</th>
            {/*<th className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("SERVICES.TABLE.LOCAL_ID")}</th>*/}
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("SERVICES.TABLE.SHORT_CODE")}</th>
            {/*<th className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("SERVICES.TABLE.SENDER_ID")}</th>*/}
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("SERVICES.TABLE.PRICE")}</th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("SERVICES.TABLE.PERIOD")}</th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("SERVICES.TABLE.FREE_PERIOD")}</th>
            {/*<th className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("SERVICES.TABLE.OPERATOR_ID")}</th>*/}
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("SERVICES.TABLE.OPERATOR_NAME")}</th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("SERVICES.TABLE.IS_SUB")}</th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("SERVICES.TABLE.IS_BILLING")}</th>
            {/*<th className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("SERVICES.TABLE.SMS_CRED_ID")}</th>*/}
            {/*<th className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("SERVICES.TABLE.BILL_CRED_ID")}</th>*/}
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("SERVICES.TABLE.STATUS")}</th>
          </tr>
          </thead>
          <tbody className="text-gray-800 text-sm">
          {
            services.map((it) =>
              <tr key={it.serviceId} className={cx('border')}>
                {/*<td className="text-left py-3 px-2">{it.serviceId}</td>*/}
                <td className="text-left py-3 px-2">{it.serviceName}</td>
                {/*<td className="text-left py-3 px-2">{it.operatorServiceId}</td>*/}
                {/*<td className="text-left py-3 px-2">{it.legacyServiceId}</td>*/}
                {/*<td className="text-left py-3 px-2">{it.legacyProductId}</td>*/}
                {/*<td className="text-left py-3 px-2">{it.partnerId}</td>*/}
                <td className="text-left py-3 px-2">{it.partnerName}</td>
                {/*<td className="text-left py-3 px-2">{it.localId}</td>*/}
                <td className="text-left py-3 px-2">{it.shortCode}</td>
                {/*<td className="text-left py-3 px-2">{it.senderId}</td>*/}
                <td className="text-left py-3 px-2">{it.price}</td>
                <td className="text-left py-3 px-2">{it.period}</td>
                <td className="text-left py-3 px-2">{it.freePeriod}</td>
                {/*<td className="text-left py-3 px-2">{it.operatorId}</td>*/}
                <td className="text-left py-3 px-2">{it.operatorName}</td>
                <td className="text-left py-3 px-2">{it.isSub ? 'Yes' : 'No'}</td>
                <td className="text-left py-3 px-2">{it.isBilling ? 'Yes' : 'No'}</td>
                {/*<td className="text-left py-3 px-2">{it.smsCredId}</td>*/}
                {/*<td className="text-left py-3 px-2">{it.billCredId}</td>*/}
                <td className="text-left py-3 px-2">{it.status ? 'Active' : 'Inactive'}</td>
              </tr>
            )
          }
          </tbody>
        </table>
      </div>

      <Modal isOpen={isNewServiceModalOpen} close={closeNewServiceModal} title="Add new service" >
        <NewServiceModal close={closeNewServiceModal} />
      </Modal>
    </div>
  );
}

export default Services;
