import React, {useEffect, useState} from 'react';
import {FiChevronRight, FiHome} from "react-icons/fi";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import cx from "classnames";
import moment from "moment/moment";
import DataTable from 'react-data-table-component';
import {getAllRegisteredEmails} from "../services/campaignServices";
import Modal from "./Modal";

function EmailMsgWrapper({ msg }) {
  const [isShowMore, setIsShowMore] = useState(false);

  return (
    <td className="text-left p-3 max-w-[500px]">
      <div className="flex justify-between items-center gap-3">
        <span style={isShowMore ? {} : { maxWidth: "calc(100% - 70px)", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
          {msg}
        </span>
        { msg.length > 60 &&
          <span
            onClick={() => setIsShowMore(!isShowMore)}
            className="cursor-pointer text-xs font-semibold text-sky-600"
          >
            {isShowMore ? 'show less' : 'show more'}
          </span>
        }
      </div>
    </td>
  );
}

const ShowMoreText = ({ text, maxLength = 100, openEmailMsgModal, msg }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleShowMore = () => {
    setIsExpanded(!isExpanded);
  };

  if (text.length <= maxLength) {
    return <span>{text}</span>;
  }

  return (
    <div className="flex items-center gap-2 text-left p-2" onClick={()=> openEmailMsgModal(msg) } >
      {isExpanded ? text : `${text.substring(0, maxLength)}... `}
      {/*<button*/}
      {/*  onClick={toggleShowMore}*/}
      {/*  className="cursor-pointer text-xs font-semibold text-sky-600 whitespace-nowrap"*/}
      {/*>*/}
      {/*  {isExpanded ? 'Show Less' : 'Show More'}*/}
      {/*</button>*/}
    </div>
  );
};

function RegisteredEmailContacts(props) {

  const {t} = useTranslation();

  const [registeredEmails, setRegisteredEmails] = useState([]);
  const [isEmailMsgModalOpen, setIsEmailMsgModalOpen] = useState(false);
  const [emailMsgModalData, setEmailMsgModalData] = useState({});

  const openEmailMsgModal = (data)=>{
    setEmailMsgModalData(data);
    setIsEmailMsgModalOpen(true);
  }
  const closeEmailMsgModal = ()=>{
    setIsEmailMsgModalOpen(false);
    setEmailMsgModalData({});
  }

  const fetchRegisteredEmails = async () => {
    await getAllRegisteredEmails()
      .then((res) => setRegisteredEmails(res.results))
    .catch((err)=>{console.log(err)});
  }

  useEffect(()=>{
    fetchRegisteredEmails();
  }, []);

  const columns = [
    {
      name: 'Contact Name',
      selector: row => row.contactName,
      sortable: true,
    },
    {
      name: 'Email',
      selector: row => row.email,
      sortable: true,
    },
    {
      name: 'Phone',
      selector: row => row.phone,
      sortable: true,
    },
    {
      name: 'Company Name',
      selector: row => row.companyName,
      sortable: true,
    },
    {
      name: 'Product Name',
      selector: row => row.productName,
      sortable: true,
    },
    {
      name: 'Email Message',
      cell: row => <ShowMoreText text={row.emailMessage} maxLength={50} openEmailMsgModal={openEmailMsgModal} msg={row} />,
    },
    {
      name: 'Date In',
      selector: row => moment(row.datein).calendar(),
      sortable: true,
    },
  ];

  return (
    <div>
      <div className="text-gray-500 flex items-center gap-2 mt-2 mb-6">
        <span className="text-xs font-semibold flex items-center">
          <FiHome size={12}/> <FiChevronRight size={12}/>
        </span>
        <h4 className="text-xs font-semibold text-gray-500">{t("REGISTERED_EMAILS.TITLE")}</h4>
      </div>
      <div className="flex items-center justify-between mb-6">
        <h1 className="text-xl font-bold">{t("REGISTERED_EMAILS.TITLE")}</h1>
        {/*<Link to='/new-partner'*/}
        {/*      className="cursor-pointer bg-azure px-4 py-2 text-sm font-semibold rounded-lg text-white hover:opacity-60 hover:shadow">*/}
        {/*  {t("CAMPAIGN_STATISTICS.ADD_BTN")}*/}
        {/*</Link>*/}
      </div>
      <div className='bg-white p-0 rounded-lg mt-4 shadow overflow-x-auto'>
        <DataTable
          columns={columns}
          data={registeredEmails}
          pagination
          highlightOnHover
          pointerOnHover
          className="min-w-full border-collapse"
        />
        {/*<table className="min-w-full border-collapse" id='my-applications-table'>*/}
        {/*  <thead className="bg-gray-100 customShadow">*/}
        {/*  <tr>*/}
        {/*    <th className="text-left p-3 uppercase font-semibold text-xs">*/}
        {/*      {t("REGISTERED_EMAILS.TABLE.CONTACT_NAME")}*/}
        {/*    </th>*/}
        {/*    <th className="text-left p-3 uppercase font-semibold text-xs">*/}
        {/*      {t("REGISTERED_EMAILS.TABLE.EMAIL")}*/}
        {/*    </th>*/}
        {/*    <th className="text-left p-3 uppercase font-semibold text-xs">*/}
        {/*      {t("REGISTERED_EMAILS.TABLE.COMPANY_NAME")}*/}
        {/*    </th>*/}
        {/*    <th className="text-left p-3 uppercase font-semibold text-xs">*/}
        {/*      {t("REGISTERED_EMAILS.TABLE.PRODUCT_NAME")}*/}
        {/*    </th>*/}
        {/*    <th className="text-left p-3 uppercase font-semibold text-xs">*/}
        {/*      {t("REGISTERED_EMAILS.TABLE.PHONE")}*/}
        {/*    </th>*/}
        {/*    <th className="text-left p-3 uppercase font-semibold text-xs">*/}
        {/*      {t("REGISTERED_EMAILS.TABLE.EMAIL_MSG")}*/}
        {/*    </th>*/}
        {/*    <th className="text-left p-3 uppercase font-semibold text-xs">*/}
        {/*      {t("REGISTERED_EMAILS.TABLE.DATE_IN")}*/}
        {/*    </th>*/}
        {/*  </tr>*/}
        {/*  </thead>*/}
        {/*  <tbody className="text-gray-800 text-sm ">*/}
        {/*  {*/}
        {/*    registeredEmails.map((it) =>*/}
        {/*      <tr key={it.id} className={cx('border')}>*/}
        {/*        <td className="text-left p-3">{it.contactName}</td>*/}
        {/*        <td className="text-left p-3">{it.email}</td>*/}
        {/*        <td className="text-left p-3">{it.companyName}</td>*/}
        {/*        <td className="text-left p-3">{it.productName}</td>*/}
        {/*        <td className="text-left p-3">{it.phone}</td>*/}
        {/*        <EmailMsgWrapper msg={it.emailMessage} />*/}
        {/*        <td className="text-left p-3">{moment(it.datein).calendar()}</td>*/}
        {/*      </tr>*/}
        {/*    )*/}
        {/*  }*/}
        {/*  </tbody>*/}
        {/*</table>*/}
      </div>

      <Modal isOpen={isEmailMsgModalOpen} close={closeEmailMsgModal} title="Email message" >
        {emailMsgModalData?.emailMessage}
      </Modal>
    </div>
  );
}

export default RegisteredEmailContacts;
