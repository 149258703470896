import api from "./api";


export const getAllContentServices = async ()=>{
  try {
    const response = await api.get('/LocalApi/ContentServices');
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const getAllContentServicesByOperator = async (id)=>{

  const params = {
    OpId: id
  }

  try {
    const response = await api.get('/LocalApi/ContentServicesByOperator', {params});
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const getAllContents = async ()=>{
  try {
    const response = await api.get('/LocalApi/Contents');
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const getAllContentsByOperator = async (id)=>{

  const params = {
    OpId: id
  }

  try {
    const response = await api.get('/LocalApi/ContentByOperator', {params});
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const getAllContentsByService = async (id)=>{

  const params = {
    ServiceId: id
  }

  try {
    const response = await api.get('/LocalApi/ContentByService', {params});
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const addTextContents = async (data)=>{

  try {
    const response = await api.post('/LocalApi/AddTextContent', data);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}
