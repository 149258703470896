import React, {useEffect, useState} from 'react';
import {
  FiBarChart,
  FiBarChart2,
  FiBriefcase,
  FiChevronRight,
  FiFile,
  FiHome,
  FiPieChart,
  FiUserPlus
} from "react-icons/fi";
import {useTranslation} from "react-i18next";
import {MdNetworkCell} from "react-icons/md";
import cx from "classnames";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import {getDashboardMonthlyData, getDashboardStatistcis} from "../services/dashboardService";
import {getAllPartners} from "../services/partnerService";
import moment from "moment";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function Dashboard(props) {

  const { t } = useTranslation();

  const [currOperator, setCurrOperator] = useState(2);
  const [statistics, setstatistics] = useState({
    campaignCount: 0,
    operatorCount: 0,
    partnerCount: 0,
    serviceCount: 0,
    subscriberCount: 0
  });

  const [operatorMonthlyData, setOperatorMonthlyData] = useState([]);
  const [fullYearData, setFullYearData] = useState([]);

  const operators = [
    {id: 4, title: "STC"},
    {id: 2, title: "MOBILY"},
    {id: 3, title: "ZAIN"},
    {id: 5, title: "Omantel"},
    {id: 6, title: "ZainIraq"},
    {id: 7, title: "RedBullMobile"},
  ]

  const statisticData = [
    {id: 1, title: "Campaigns ", number: statistics.campaignCount, icon: <MdNetworkCell size={44} color="#158A9C" />, color: "#14A2B8"},
    {id: 2, title: "Operators", number: statistics.operatorCount, icon: <FiBriefcase size={44} color="#0569D8" />, color: "#027BFF"},
    {id: 3, title: "Partners", number: statistics.partnerCount, icon: <FiBarChart2 size={44} color="#258E3B" />, color: "#28A745"},
    {id: 4, title: "Services", number: statistics.serviceCount, icon: <FiUserPlus size={44} color="#D9A40D" />, color: "#FFC109"},
    {id: 5, title: "Subscribers", number: statistics.subscriberCount, icon: <FiFile size={44} color="#BC2D3B" />, color: "#DC3545"},
  ];

  const operatorStatisticTest = [
    {id: 1, month: "January", localRevenue: "2342"},
    {id: 2, month: "February", localRevenue: "2342"},
    {id: 3, month: "March", localRevenue: "2342"},
    {id: 4, month: "April", localRevenue: "2342"},
  ];

  const chartDataTest = {
    labels: operatorMonthlyData.map(item => `${item.month} / ${item.year}`),
    datasets: [
      {
        label: 'local revenue',
        data: operatorMonthlyData.map(item => item.localRevenue),
        backgroundColor: 'rgba(111, 66, 193, 0.5)',
        borderColor: 'rgba(111, 66, 193, 1)',
        borderWidth: 1,
      }
    ],
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true
      }
    },
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      title: {
        display: true,
        text: 'Monthly operator revenue',
      },
    },
  };

  const fetchStatistics = async () => {
    await getDashboardStatistcis()
      .then((res)=>{
        setstatistics(res.results);
      })
      .catch((err)=>{console.log(err)});
  }

  const fetchDashboardMonthlyData = async ()=>{
    await getDashboardMonthlyData(currOperator)
      .then((res)=>{
        setOperatorMonthlyData(res.results);
      })
      .catch((err)=>{console.log(err)});
  }

  useEffect(() => {
    fetchStatistics();
  }, []);

  useEffect(() => {
    fetchDashboardMonthlyData()
  }, [currOperator]);

  function generateDefaultMonths(year) {
    return Array.from({ length: 12 }, (_, index) => ({
      month: index + 1,  // month from 1 to 12
      year: year,
      hits: 0,
      localRevenue: '0.00',
      revenueUSD: '0.00'
    }));
  }

  function prepareFullYearData(operatorMonthlyData) {
    const currentYear = new Date().getFullYear(); // Use the current year or a specific year
    const defaultData = generateDefaultMonths(currentYear);
    const monthDataMap = new Map(operatorMonthlyData.map(item => [item.month, item]));

    return defaultData.map(month => monthDataMap.get(month.month) || month);
  }

  useEffect(() => {
    const newFullYearData = prepareFullYearData(operatorMonthlyData);
    setFullYearData(newFullYearData);
  }, [operatorMonthlyData]);

  return (
    <div>
      <div className="text-gray-500 flex items-center gap-2 mt-2 mb-6">
        <span className="text-xs font-semibold flex items-center">
          <FiHome size={12}/> <FiChevronRight size={12}/>
        </span>
        <h4 className="text-xs font-semibold text-gray-500">{t("GLOBAL.DASHBOARD")}</h4>
      </div>
      <div className="grid lg:grid-cols-6 grid-cols-2 gap-3 mb-4" >
        {
          statisticData.map((item, index) => (
            <div
              key={item.id}
              style={{backgroundColor: item.color}}
              className="rounded-lg min-h-20 flex flex-col items-start px-4 py-3 relative">
              <p className="text-3xl font-bold text-white mb-5">{item.number}</p>
              <p className="text-lg font-light text-white">{item.title}</p>
              <div className="absolute right-4 top-3" >
                {item.icon}
              </div>
            </div>
          ))
        }
      </div>
      <div className='bg-white p-3 rounded-lg mt-4 shadow'>
        <div className="flex flex-col lg:flex-row items-center justify-between gap-3 mb-3">
          <div className="flex items-center gap-3">
            <FiPieChart size={20}/>
            <p className="text-sm font-semibold">Monthly revenue</p>
          </div>
          <div className="flex items-center justify-center gap-3 flex-wrap">
            {
              operators.map((item, index) => (
                <button
                  key={item.id}
                  onClick={() => setCurrOperator(item.id)}
                  className={cx("border border-sky-600 text-sm lg:text-base rounded-lg lg:px-4 px-2 lg:py-1.5 py-1 font-semibold", {
                    "bg-sky-600 text-white": currOperator === item.id,
                    'text-sky-600': currOperator !== item.id
                  })}
                >
                  {item.title}
                </button>
              ))
            }
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 items-center justify-between gap-10 h-max" >
          <div className="relative overflow-x-auto overflow-y-auto w-full">
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-100 ">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Month
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Total billed
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Locale revenue
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Total Revenue USD
                  </th>
                </tr>
              </thead>
              <tbody>
              {
                fullYearData.map((item, index) => (
                  <tr key={item.month + item.year + index} className="bg-white border-b hover:bg-gray-50 ">
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap w-20">
                      {item.month} / {item.year}
                    </th>
                    <td className="px-6 py-4">
                      {item.hits}
                    </td>
                    <td className="px-6 py-4">
                      {item.localRevenue}
                    </td>
                    <td className="px-6 py-4">
                      {item.revenueUSD}
                    </td>
                  </tr>
                ))
              }
              </tbody>
            </table>
            <div className="mt-3 text-xs font-semibold text-gray-600" >
              <p>Last update : {fullYearData[0]?.lastUpdated && moment(fullYearData[0]?.lastUpdated).format("DD/MM/YYYY")}</p>
            </div>
          </div>
          <div className="w-full h-full" >
            {
              operatorMonthlyData.length > 0 &&
              <Bar data={chartDataTest} options={options} />
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
