import React, {useEffect, useState} from 'react';
import {motion} from "framer-motion";
import {Link, useLocation} from "react-router-dom";
import {FiChevronDown, FiChevronUp, FiCircle, FiGrid, FiTable, FiTrello, FiX} from "react-icons/fi";
import adminLogo from '../assets/AdminLTELogo.png';
import {FaUsers} from "react-icons/fa";
import cx from "classnames";

const MenuData = [
  {id: 1, title: "Dashboard", icon: <FiGrid/>, route: "/"},
  {id: 2, title: "Partners", icon: <FaUsers/>, route: "/partners"},
  {id: 3, title: "Service Management", subData: [
      {id: 1, title: "Services", route: "/services"},
      {id: 2, title: "Service Statistics", route: "/service-statistics"},
    ]
  },
  {id: 4, title: "Content Management", icon: <FiTable/>, route: "/content-management"},
  {id: 5, title: "Campaign Management", subData: [
      {id: 1, title: "Campaigns", route: "/campaigns"},
      // {id: 2, title: "Campaign Reports", route: "/campaign-reports"},
      {id: 3, title: "Registered Email Contacts", route: "/registered-email-contacts"},
    ]
  },
  {id: 6, title: "Technical", subData: [
      {id: 1, title: "Operator", route: "/operator"},
      {id: 2, title: "Methods", route: "/methods"},
    ]
  },
  {id: 7, title: "Administration", subData: [
      {id: 1, title: "Company", route: "/company"},
      {id: 2, title: "Partner", route: "/partners"},
      {id: 3, title: "Publisher", route: "/publisher"},
    ]
  },
];

function MenuItem({item, isBarOpen, selectedNav, close}){
  return (
    <li>
      <Link
        onClick={()=> close() }
        to={item.route}
            className={cx('flex border border-white items-center gap-2 p-2 rounded-lg cursor-pointer hover:border-gray-300', {
              'bg-azure text-white': selectedNav === item.route,
              'justify-center': !isBarOpen
            })}>
        {item.icon}
        {isBarOpen && <span className='text-md font-semibold'>{item.title}</span>}
      </Link>
    </li>
  )
}

function DropMenuItem({item, isBarOpen, selectedNav, close}) {

  const [isOpen, setIsOpen] = useState(false);

  return (
    <li className="w-full">
      <button onClick={() => setIsOpen(!isOpen)}
              className={cx('flex border border-white items-center w-full justify-between gap-2 p-2 rounded-lg cursor-pointer hover:border-gray-300')}>
        <div className={cx("flex items-center gap-2 ", {'w-[85%]': isBarOpen, 'w-full justify-center': !isBarOpen})} >
          <FiTrello/>
          {isBarOpen && <span className='text-md font-semibold text-left truncate'>{item.title}</span>}
        </div>
        {
          isBarOpen &&
          <div>
            {
              isOpen
                ? <FiChevronUp size={18} className="text-gray-500"/>
                : <FiChevronDown size={18} className="text-gray-500"/>
            }
          </div>
        }
      </button>
      {
        isOpen &&
        <div className="bg-gray-100 rounded-lg p-2 w-full" >
          {
            item.subData.map((subItem, index) =>
              <Link
                onClick={()=> close() }
                to={subItem.route}
                key={subItem.id}
                className={cx('flex border border-white items-center gap-2 p-2 rounded-lg cursor-pointer hover:border-gray-300', {
                  'bg-azure text-white': selectedNav === subItem.route,
                  'justify-center': !isBarOpen
                })}>
                <FiCircle/>
                {isBarOpen && <span className='text-sm font-semibold text-left truncate'>{subItem.title}</span>}
              </Link>
            )
          }
        </div>
      }
    </li>
  )
}

function MobileSideMenu({closeMobileMenu}) {

  const location = useLocation();

  const [selectedNav, setSelectedNav] = useState("/");

  useEffect(() => {
    if(location.pathname !== selectedNav ){
      setSelectedNav(location.pathname);
    }
  }, [location]);

  return (
    <motion.div
      className="fixed top-0 left-0 z-50 h-[100vh] w-full bg-white p-6 overflow-x-hidden overflow-y-auto"
      initial={{opacity: 0, x: '-100%'}}
      animate={{opacity: 1, x: 0}}
      exit={{opacity: 0, x: '-100%'}}
      transition={{duration: 0.3}}
    >
      <div className="flex items-center justify-between mb-5">
        <Link to="/" onClick={() => closeMobileMenu()} className=" flex items-center gap-5">
          <div className="h-auto w-14">
            <img src={adminLogo} alt="Educaps" className="h-full w-full object-contain"/>
          </div>
          <p className="text-sm">
            <span className="font-semibold text-base">Taqniyat </span>
            tech
          </p>
        </Link>
        <button onClick={() => closeMobileMenu()} className="lg:hidden block">
          <FiX size={22} className="text-primary"/>
        </button>
      </div>
      <ul className="flex flex-col gap-2">
        {
          MenuData.map(item => {
            if (item.subData && item.subData.length > 0) {
              return <DropMenuItem key={item.id} item={item} close={closeMobileMenu} isBarOpen={true}
                                   selectedNav={selectedNav}/>
            } else {
              return <MenuItem key={item.id} item={item} close={closeMobileMenu} isBarOpen={true}
                               selectedNav={selectedNav}/>
            }
          })
        }
      </ul>
      <div className="text-left text-xs font-semibold text-gray-500 absolute bottom-3">
        Service delivery platform - 2024 © Taqniyat tech All Rights Reserved.
      </div>
    </motion.div>
  );
}

export default MobileSideMenu;
