import React from 'react';

function CampaignReports(props) {
  return (
    <div>
      CampaignReports
    </div>
  );
}

export default CampaignReports;
