import api from "./api";

export const getAllPartners = async ()=>{
  try {
    const response = await api.get('/LocalApi/Partners');
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const addPartner = async (data)=>{
  try {
    const response = await api.post('/LocalApi/AddPartner', data);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const editPartner = async (data)=>{
  try {
    const response = await api.post('/LocalApi/UpdatePartner', data);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}


export const getAllCompanies = async ()=>{
  try {
    const response = await api.get('/LocalApi/Companies');
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}


export const showPartners = async (id)=>{
  const params = {
    PartnerId: id
  };
  try {
    const response = await api.get(`/LocalApi/Partner`, {params});
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const getPartnerServicesRevenues = async (OperatorId, partnerId, FromDate, ToDate)=>{
  try {
    const response = await api.get('/LocalApi/PartnerServicesRevenues',{
      params: {
        OperatorId: OperatorId,
        PartnerId: partnerId,
        FromDate: FromDate,
        ToDate: ToDate
      }
    });
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}


