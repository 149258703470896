import api from "./api";

export const getAllServices = async ()=>{
  try {
    const response = await api.get('/LocalApi/Services');
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const getServicesByOperator = async (id)=>{
  try {
    const response = await api.get('/LocalApi/ServicesByOperator/', {
      params: {
        OpId: id
      }
    });
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const getServicesByPartner = async (id)=>{
  try {
    const response = await api.get('/LocalApi/ServicesByPartner', {
      params: {
        PartnerId: id
      }
    });
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const getServicesRevenue = async (OperatorId, ServiceId, FromDate, ToDate)=>{
  try {
    const response = await api.get('/LocalApi/ServicesRevenues/', {
      params: {
        OperatorId: OperatorId,
        ServiceId: ServiceId,
        FromDate: FromDate,
        ToDate: ToDate
      }
    });
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const addService = async (data)=>{
  console.log(data);
  try {
    const response = await api.post('/LocalApi/AddService', data);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}
