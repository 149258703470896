import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {FiChevronRight, FiEye, FiHome} from "react-icons/fi";
import {Link} from "react-router-dom";
import {getAllCampaigns} from "../services/campaignServices";
import cx from "classnames";
import * as XLSX from 'xlsx';
import FullModal from "./FullModal";
import moment from "moment";

function ServicesModal({initData}){

  const {t} = useTranslation();

  return(
    <div>
      <table className="min-w-full border-collapse" id='my-applications-table'>
        <thead className="bg-gray-100 customShadow">
        <tr>
          <th className="text-left py-3 px-2 uppercase font-semibold text-xs">
            {t("SERVICES.TABLE.SERVICE_NAME")}
          </th>
          <th className="text-left py-3 px-2 uppercase font-semibold text-xs">
            {t("SERVICES.TABLE.OPERATOR_NAME")}
          </th>
          <th className="text-left py-3 px-2 uppercase font-semibold text-xs">
            {t("SERVICES.TABLE.START_DATE")}
          </th>
          <th className="text-left py-3 px-2 uppercase font-semibold text-xs">
            {t("SERVICES.TABLE.END_DATE")}
          </th>
          <th className="text-left py-3 px-2 uppercase font-semibold text-xs">
            {t("SERVICES.TABLE.STATUS")}
          </th>
        </tr>
        </thead>
        <tbody className="text-gray-800 text-sm">
        {
          initData.map((it) =>
            <tr key={it.campaignServiceId} className={cx('border')}>
              <td className="text-left py-3 px-2">{it.serviceName}</td>
              <td className="text-left py-3 px-2">{it.operatorName}</td>
              <td className="text-left py-3 px-2">{moment(it.startDate).format('dd/MM/yyyy')}</td>
              <td className="text-left py-3 px-2">{moment(it.endDate).format('dd/MM/yyyy')}</td>
              <td className="text-left py-3 px-2">{it.isActive === 1 ? "Active" : "Not Active"}</td>
            </tr>
          )
        }
        </tbody>
      </table>
    </div>
  )
}

function Campaigns(props) {

  const {t} = useTranslation();

  const [campaigns, setCampaigns] = useState([]);
  const [isServicesModalOpen, setIsServicesModalOpen] = useState(false);
  const [servicesModalData, setServicesModalData] = useState([]);

  const openServicesModal = (services) => {
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setServicesModalData(services);
    setIsServicesModalOpen(true);
  }
  const closeServicesModal = () => {
    document.getElementsByTagName('body')[0].style.overflowY="scroll";
    setIsServicesModalOpen(false);
    setServicesModalData([]);
  }

  const fetchAllCampaigns = async () => {
    await getAllCampaigns()
      .then((res) => setCampaigns(res.results))
      .catch((err) => {
        console.log(err)
      });
  }

  useEffect(() => {
    fetchAllCampaigns();
  }, []);

  function exportToExcel(tableData, fileName) {
    const ws = XLSX.utils.json_to_sheet(tableData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }

  return (
    <div>
      <div className="text-gray-500 flex items-center gap-2 mt-2 mb-6">
        <span className="text-xs font-semibold flex items-center">
          <FiHome size={12}/> <FiChevronRight size={12}/>
        </span>
        <h4 className="text-xs font-semibold text-gray-500">{t("CAMPAIGNS.TITLE")}</h4>
      </div>
      <div className="flex items-center justify-between mb-6">
        <h1 className="text-xl font-bold">{t("CAMPAIGNS.TITLE")}</h1>
        {/*<Link to='/new-partner'*/}
        {/*      className="cursor-pointer bg-azure px-4 py-2 text-sm font-semibold rounded-lg text-white hover:opacity-60 hover:shadow">*/}
        {/*  {t("CAMPAIGNS.ADD_BTN")}*/}
        {/*</Link>*/}
      </div>
      <div className='bg-white p-0 rounded-lg mt-4 shadow overflow-x-auto'>
        <table className="min-w-full border-collapse" id='my-applications-table'>
          <thead className="bg-gray-100 customShadow">
          <tr>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">
              {t("CAMPAIGNS.TABLE.CAMPAIGN_ID")}
            </th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">
              {t("CAMPAIGNS.TABLE.DESCRIPTION")}
            </th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">
              {t("CAMPAIGNS.TABLE.PUBLISHER_NAME")}
            </th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">
              {t("CAMPAIGNS.TABLE.IS_ACTIVE")}
            </th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">
                {t("CAMPAIGNS.TABLE.ALLOCATED_COST")}
              </th>
              <th className="text-left py-3 px-2 uppercase font-semibold text-xs">
                {t("CAMPAIGNS.TABLE.DAILY_TARGET_CONVERSION")}
              </th>
              <th className="text-left py-3 px-2 uppercase font-semibold text-xs">
                {t("CAMPAIGNS.TABLE.MONTHLY_TARGET_CONVERSION")}
              </th>
              <th className="text-left py-3 px-2 uppercase font-semibold text-xs">
                {t("CAMPAIGNS.TABLE.CAMPAIGN_SERVICES")}
              </th>
            </tr>
          </thead>
          <tbody className="text-gray-800 text-sm">
          {
            campaigns.map((it) =>
              <tr key={it.campaignId} className={cx('border')}>
                <td className="text-left py-3 px-2">{it.campaignId}</td>
                <td className="text-left py-3 px-2">{it.description}</td>
                <td className="text-left py-3 px-2">{it.publisherName}</td>
                <td className="text-left py-3 px-2">{it.isActive === 1 ? "True" : "False"}</td>
                <td className="text-left py-3 px-2">{it.allocatedCost}</td>
                <td className="text-left py-3 px-2">{it.dailyTargetConversion}</td>
                <td className="text-left py-3 px-2">{it.monthlyTargetConversion}</td>
                <td className="flex items-center justify-center py-3 px-2" >
                  <button onClick={()=> openServicesModal(it.campaignServices) } className="hover:text-azure" >
                    <FiEye size={18} />
                  </button>
                </td>
              </tr>
            )
          }
          </tbody>
        </table>
      </div>

      <FullModal isOpen={isServicesModalOpen} close={closeServicesModal} title={t("CAMPAIGNS.TABLE.CAMPAIGN_SERVICES")} >
        <ServicesModal initData={servicesModalData} />
      </FullModal>
    </div>
  );
}

export default Campaigns;
