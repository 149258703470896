import React, {useEffect, useState} from 'react';
import {FiCalendar, FiChevronRight, FiHome} from "react-icons/fi";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import FullModal from "../FullModal";
import * as XLSX from 'xlsx';
import cx from "classnames";
import DatePicker from "react-datepicker";
import {uploadMobilyRevenueReport} from "../../services/reportsUploadService";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

function UploadFromFileModal(){

  const [file, setFile] = useState(null);
  const [fileData, setFileData] = useState([]);

  const uploadNewFile = ()=>{
    setFileData([]);
    setFile(null);
  }

  const handleFile = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const arrayBuffer = event.target.result;
      const workbook = XLSX.read(arrayBuffer, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);
      setFileData(jsonData);
    };
    reader.readAsArrayBuffer(file);
  };

  useEffect(() => {
    if(file){
      handleFile(file);
    }
  }, [file]);

  if(!file){
    return (
      <div className="flex items-center justify-center w-full h-full">
        <div className="w-1/3" >
          <input
            className="block w-full text-sm text-slate-500
                          file:mr-4 file:py-1.5 file:px-2 file:rounded-md
                          file:border-0 file:text-sm file:font-semibold
                          file:bg-sky-50 file:text-sky-700 file:cursor-pointer
                          hover:file:bg-sky-100 border rounded-lg appearance-none"
            type="file"
            onChange={(e) => setFile(e.target.files[0])}
          />
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className="overflow-y-auto h-[77vh] flex-wrap">
        <table className="min-w-full border-collapse">
          <thead className="bg-gray-100 customShadow">
          <tr>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">Service Name</th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">Service Id</th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">Shortcode</th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">Date</th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">Total Subscribers</th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">Hits</th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">Cancelled</th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">New Subscribers</th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">Renew Subscribers</th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">Revenue</th>
          </tr>
          </thead>
          <tbody className="text-gray-800 text-sm">
          {fileData.map((row, index) => (
            <tr className={cx('border')} key={index}>
              <td className="text-left py-3 px-2">{row['Service Name']}</td>
              <td className="text-left py-3 px-2">{row['Service Id']}</td>
              <td className="text-left py-3 px-2">{row['Shortcode']}</td>
              <td className="text-left py-3 px-2">{row['Date']}</td>
              <td className="text-left py-3 px-2">{row['TotalSub']}</td>
              <td className="text-left py-3 px-2">{row['Hits']}</td>
              <td className="text-left py-3 px-2">{row['Cancelled']}</td>
              <td className="text-left py-3 px-2">{row['NewSub']}</td>
              <td className="text-left py-3 px-2">{row['RenewSub']}</td>
              <td className="text-left py-3 px-2">{row['Revenue']}</td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
      <div className="flex items-center justify-between mt-4">
        <button onClick={()=> uploadNewFile() } className="bg-white px-5 py-2 rounded-lg border text-gray-600 font-semibold">
          New file
        </button>
        <button className="bg-azure px-5 py-2 rounded-lg text-white font-semibold">
          Upload
        </button>
      </div>
    </div>
  )
}

function Mobily(props) {

  const {t} = useTranslation();
  const [isNewModalOpen, setIsNewModalOpen] = useState(false);

  const [file, setFile] = useState(null);
  const [date, setDate] = useState(null);
  const [fileData, setFileData] = useState([]);

  const openNewFileModal = () => {
    setIsNewModalOpen(true);
  }
  const closeNewFileModal = () => {
    setIsNewModalOpen(false);
  }

  const showFinishSwal = () => {
    withReactContent(Swal)
      .fire({
        title: "File uploaded with success",
        icon: "success",
        showConfirmButton: true,
      })
      .then(()=>{
        setDate(null);
        setFile(null);
        setFileData([]);
      })
  }

  const showLoadingSwal = () => {
    withReactContent(Swal)
      .fire({
        title: "Upload loading",
        icon: "info",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      })
  }

  const uploadFile = async () => {

    showLoadingSwal();

    const data = {
      "operatorId": 2,
      "dated": date,
      "reportType": 0,
      "serviceLogs": fileData
    };

    await uploadMobilyRevenueReport(data)
      .then((res)=>{
        Swal.close();
        showFinishSwal();
        console.log(res);
      })
      .catch((err)=>{
        Swal.close();
        console.log(err)
      });
  }

  const handleFile = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const arrayBuffer = event.target.result;
      const workbook = XLSX.read(arrayBuffer, {type: 'array'});
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      const mappedData = jsonData.map(row => ({
        serviceName: row['Service Name'],
        serviceID: row['Service Id'],
        shortCode: row['Shortcode'],
        date: row['Date'],
        totalSub: row['TotalSub'],
        hits: row['Hits'],
        cancelled: row['Cancelled'],
        newSub: row['NewSub'],
        renewSub: row['RenewSub'],
        revenue: row['Revenue'],
      }));
      setFileData(mappedData);
    };
    reader.readAsArrayBuffer(file);
  };

  useEffect(() => {
    if (file) {
      handleFile(file);
    }
  }, [file]);

  const handleDateChange = (selectedDate) => {
    if (selectedDate) {
      const updatedDate = new Date(selectedDate);
      updatedDate.setHours(13, 0, 0, 0);
      setDate(updatedDate);
      console.log(updatedDate);
    } else {
      setDate(null);
    }
  };

  return (
    <div>
      <div className="text-gray-500 flex items-center gap-2 mt-2 mb-6">
        <span className="text-xs font-semibold flex items-center">
          <FiHome size={12}/> <FiChevronRight size={12}/>
        </span>
        <h4 className="text-xs font-semibold text-gray-500">{t("OPERATOR_REPORTS.TITLE")}</h4>
        <span className="text-xs font-semibold flex items-center">
          <FiChevronRight size={12}/>
        </span>
        <h4 className="text-xs font-semibold text-gray-500">{t("OPERATOR_REPORTS.MOBILY.TITLE")}</h4>
      </div>
      <div className="flex items-center justify-between mb-6">
        <h1 className="text-xl font-bold">{t("OPERATOR_REPORTS.MOBILY.TITLE")}</h1>
        <div className="flex items-center gap-3">
          <div className="border rounded-lg px-2 py-1.5 text-sm flex items-center gap-2 bg-white">
            <FiCalendar className="text-gray-400"/>
            <DatePicker
              selected={date}
              onChange={handleDateChange}
              type="text"
              dateFormat="dd/MM/yyyy"
              placeholderText='Date'
              isClearable
            />
          </div>
          <input
            className="block w-full text-sm text-slate-500
                          file:mr-4 file:py-1.5 file:px-2 file:rounded-md
                          file:border-0 file:text-sm file:font-semibold
                          file:bg-sky-50 file:text-sky-700 file:cursor-pointer
                          hover:file:bg-sky-100 border rounded-lg appearance-none"
            type="file"
            disabled={date === null}
            onChange={(e) => setFile(e.target.files[0])}
          />
          <button
            onClick={() => uploadFile()}
            disabled={file === null}
            className={cx("cursor-pointer whitespace-nowrap w-max px-4 py-2 text-sm font-semibold rounded-lg text-white hover:opacity-60 hover:shadow", {
              'bg-gray-400': file === null,
              'bg-azure': file !== null,
            })}
          >
            {t("OPERATOR_REPORTS.MOBILY.ADD_BTN")}
          </button>
        </div>
        {/*<button*/}
        {/*  onClick={() => openNewFileModal()}*/}
        {/*  className="cursor-pointer bg-azure px-4 py-2 text-sm font-semibold rounded-lg text-white hover:opacity-60 hover:shadow">*/}
        {/*  {t("OPERATOR_REPORTS.MOBILY.ADD_BTN")}*/}
        {/*</button>*/}
      </div>

      <div className="overflow-y-auto h-[90vh] flex-wrap">
        <table className="min-w-full border-collapse">
          <thead className="bg-gray-100 customShadow">
          <tr>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">Service Name</th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">Service Id</th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">Shortcode</th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">Date</th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">Total Subscribers</th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">Hits</th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">Cancelled</th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">New Subscribers</th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">Renew Subscribers</th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">Revenue</th>
          </tr>
          </thead>
          <tbody className="text-gray-800 text-sm">
          {fileData.map((row, index) => (
            <tr className={cx('border')} key={index}>
              {/*<td className="text-left py-3 px-2">{row['Service Name']}</td>*/}
              {/*<td className="text-left py-3 px-2">{row['Service Id']}</td>*/}
              {/*<td className="text-left py-3 px-2">{row['Shortcode']}</td>*/}
              {/*<td className="text-left py-3 px-2">{row['Date']}</td>*/}
              {/*<td className="text-left py-3 px-2">{row['TotalSub']}</td>*/}
              {/*<td className="text-left py-3 px-2">{row['Hits']}</td>*/}
              {/*<td className="text-left py-3 px-2">{row['Cancelled']}</td>*/}
              {/*<td className="text-left py-3 px-2">{row['NewSub']}</td>*/}
              {/*<td className="text-left py-3 px-2">{row['RenewSub']}</td>*/}
              {/*<td className="text-left py-3 px-2">{row['Revenue']}</td>*/}
              <td className="text-left py-3 px-2">{row.serviceName}</td>
              <td className="text-left py-3 px-2">{row.serviceID}</td>
              <td className="text-left py-3 px-2">{row.shortCode}</td>
              <td className="text-left py-3 px-2">{row.date}</td>
              <td className="text-left py-3 px-2">{row.totalSub}</td>
              <td className="text-left py-3 px-2">{row.hits}</td>
              <td className="text-left py-3 px-2">{row.cancelled}</td>
              <td className="text-left py-3 px-2">{row.newSub}</td>
              <td className="text-left py-3 px-2">{row.renewSub}</td>
              <td className="text-left py-3 px-2">{row.revenue}</td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>

      <FullModal isOpen={isNewModalOpen} title="Upload data from file" close={closeNewFileModal}>
        <UploadFromFileModal close={closeNewFileModal}/>
      </FullModal>
    </div>
  );
}

export default Mobily;
