import React, {useEffect, useRef, useState} from 'react';
import {
  FiAlertCircle,
  FiCheckCircle,
  FiChevronDown, FiChevronLeft,
  FiChevronRight,
  FiChevronUp,
  FiEdit, FiEye,
  FiHome,
  FiTrash,
  FiXOctagon
} from "react-icons/fi";
import cx from "classnames";
import {useTranslation} from "react-i18next";
import Modal from "./Modal";
import {useForm} from "react-hook-form";
import {Link} from "react-router-dom";
import {getAllPartners} from "../services/partnerService";
import moment from "moment";

const dataTest = [
  {id: 1, intitule: "Partner 1", token: "fljbssb42zsfjknskjiorb", validity: "9/23/2023 11:00:00 AM", enabled: true},
  {id: 2, intitule: "Partner 2", token: "fljbssb42zsfjknskjiorb", validity: "9/23/2023 11:00:00 AM", enabled: true},
  {id: 3, intitule: "Partner 3", token: "fljbssb42zsfjknskjiorb", validity: "9/23/2023 11:00:00 AM", enabled: true},
  {id: 4, intitule: "Partner 4", token: "fljbssb42zsfjknskjiorb", validity: "9/23/2023 11:00:00 AM", enabled: true},
  {id: 5, intitule: "Partner 5", token: "fljbssb42zsfjknskjiorb", validity: "9/23/2023 11:00:00 AM", enabled: true},
]

function AddClientModal(){

  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();
  const { t, i18n } = useTranslation();
  const [enabled, setEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const [clientCreated, setClientCreated] = useState(false);

  const onSubmit = async (data) => {
    console.log(data);
  }

  if(clientCreated){
    return(
      <div className='min-w-[35vw] flex flex-col items-center justify-center gap-5' >
        <FiCheckCircle size={84} className='text-green-700 ' />
        <h1 className='text-center text-xl font-semibold mb-2' >{t("HOME.USERS_VIEW.USER_CREATED_MSG")}</h1>
      </div>
    )
  }

  return(
    <div className='max-w-[45vw]' >
      { errorCode.length > 0 && <p className='text-sm font-semibold text-[#D6323A] mb-5' >{t(`ERRORS.${errorCode}`)}</p>}
      <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col w-full' >
        <div className='grid grid-cols-2 gap-3 mb-3'>
          <div className='flex flex-col'>
            <div className='px-2.5 mb-0.5 flex items-center justify-between'>
              <label className='text-xs text-gray-500 font-semibold'>{t("GLOBAL.LAST_NAME")} *</label>
              {errors.lastName &&
                <label className='text-xs text-red-700 font-semibold'>{t("GLOBAL.REQUIRED_ERROR")}</label>}
            </div>
            <input
              className='border rounded-lg px-2 py-1.5 text-sm'
              placeholder={t("GLOBAL.LAST_NAME")}
              {...register("lastName", {required: true})}
            />
          </div>
          <div className='flex flex-col'>
            <div className='px-2.5 mb-0.5 flex items-center justify-between'>
              <label className='text-xs text-gray-500 font-semibold'>{t("GLOBAL.FIRST_NAME")} *</label>
              {errors.firstName &&
                <label className='text-xs text-red-700 font-semibold'>{t("GLOBAL.REQUIRED_ERROR")}</label>}
            </div>
            <input
              className='border rounded-lg px-2 py-1.5 text-sm'
              placeholder={t("GLOBAL.FIRST_NAME")}
              {...register("firstName", {required: true})}
            />
          </div>
        </div>

        <div className='flex justify-between mt-6' >

          <label className="inline-flex items-center cursor-pointer">
            <input type="checkbox" value="" className="sr-only peer" checked={enabled} onChange={()=> setEnabled(!enabled) } />
            <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">{t("HOME.USERS_VIEW.ACTIVE_USER_BTN")}</span>
          </label>

          {
            loading ?
              <div className='border border-azure rounded-lg h-9 w-32 relative' >
                <div className='loader'/>
              </div>
              :
              <input
                type="submit"
                value={t("GLOBAL.SAVE")}
                className='border border-azure font-semibold cursor-pointer rounded-lg px-4 py-1.5 text-sm text-azure hover:text-white hover:bg-azure'
              />
          }
        </div>
      </form>
    </div>
  )
}

function DeleteModal({close, clientId, fetchClients}){

  const {t} = useTranslation();

  const submit = ()=>{
    // deleteTenant(clientId)
    //   .then((res)=>{
    //     fetchClients();
    //     close();
    //   })
    //   .catch((err)=> console.log(err) );
  }

  return(
    <div className='flex flex-col items-center' >
      <FiAlertCircle size={64} color='#D6323A' />
      <p className='mt-4 font-bold text-lg' >{t("PARTNERS.DELETE_MSG")}</p>

      <div className='w-full mt-6 flex items-center justify-between px-10' >
        <button onClick={()=> close() } className='hover:text-azure hover:border-azure flex items-center gap-2 px-2.5 py-1.5 rounded-lg border text-xs font-semibold text-gray-600' >
          {t("GLOBAL.CANCEL")}
        </button>
        <button onClick={()=> submit() } className='flex items-center gap-2 px-2.5 py-1.5 rounded-lg border border-azure bg-azure text-xs font-semibold text-white hover:shadow-lg' >
          {t("GLOBAL.DELETE")}
        </button>
      </div>
    </div>
  )
}

function Partners(props) {

  const {t} = useTranslation();

  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLastPage, setIsLastPage] = useState(false);
  const [page, setPage] = useState(1);
  const [sizeOfPage, setSizeOfPage] = useState(10);

  const [isAddClientModalOpen, setIsAddClientModalOpen] = React.useState(false);
  const [isDeleteClientModalOpen, setIsDeleteClientModalOpen] = React.useState(false);
  const [modalData, setModalData] = useState(null);

  const openAddClientModal = () => {
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsAddClientModalOpen(true);
  }
  const closeAddClientModal = () => {
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsAddClientModalOpen(false);
  }
  const openDeleteClientModal = (clientId) => {
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setModalData(clientId)
    setIsDeleteClientModalOpen(true);
  }
  const closeDeleteClientModal = () => {
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsDeleteClientModalOpen(false);
    setModalData({});
  }

  const fetchClients = () => {
    setLoading(true);
    getAllPartners(page, sizeOfPage)
      .then((data)=>{ setClients(data.results); })
      .catch((err) => {console.log(err)})
      .finally(()=> setLoading(false) )
  }

  useEffect(() => {
    fetchClients();
  }, []);

  return (
    <div>
      <div className="text-gray-500 flex items-center gap-2 mt-2 mb-6">
        <span className="text-xs font-semibold flex items-center">
          <FiHome size={12}/> <FiChevronRight size={12}/>
        </span>
        <h4 className="text-xs font-semibold text-gray-500">{t("GLOBAL.DASHBOARD")}</h4>
      </div>
      <div className="flex items-center justify-between mb-6">
        <h1 className="text-xl font-bold">{t("PARTNERS.TITLE")}</h1>
        <Link to='/new-partner'
              className="cursor-pointer bg-azure px-4 py-2 text-sm font-semibold rounded-lg text-white hover:opacity-60 hover:shadow">
          {t("PARTNERS.ADD_BTN")}
        </Link>
      </div>
      <div className='bg-white p-3 rounded-lg mt-4 shadow overflow-x-auto'>
        <table className="min-w-full border-collapse" id='my-applications-table'>
          <thead className="bg-gray-100 customShadow">
          <tr>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("PARTNERS.TABLE.INTITULE")}</th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("PARTNERS.TABLE.TOKEN")}</th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("PARTNERS.TABLE.DATE_IN")}</th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("PARTNERS.TABLE.VALIDITY")}</th>
            <th/>
          </tr>
          </thead>
          <tbody className="text-gray-800 text-sm ">
          {
            clients.map((it)=>
                <tr key={it.partnerId} className={cx('border')}>
                  <td className="text-left py-3 px-2">{it.partnerName}</td>
                  <td className="text-left py-3 px-2">{it.partnerToken}</td>
                  <td className="text-left py-3 px-2">{moment(it.dateIn).calendar()}</td>
                  <td className="text-left py-3 px-2">{moment(it.validity).calendar()}</td>
                  <td className='w-28'>
                    <div className='flex items-center gap-4'>
                      {/*<Link to={`/show-client/${it.id}`}>*/}
                      {/*  <FiEye size={16} className='hover:text-azure cursor-pointer text-sky-500'/>*/}
                      {/*</Link>*/}
                      <Link to={`/edit-partner/${it.partnerId}`}>
                        <FiEdit size={16} className='hover:text-azure cursor-pointer text-yellow-500'/>
                      </Link>
                      {/*<FiTrash onClick={() => openDeleteClientModal(it.id)} size={16}*/}
                      {/*         className='hover:text-red-700 cursor-pointer text-red-600'/>*/}
                    </div>
                  </td>
                </tr>
              )
            }
          </tbody>
        </table>
      </div>
      {/*<div className='mt-4 flex items-center justify-between'>*/}
      {/*  <div className='flex gap-3 items-center'>*/}
      {/*    <button onClick={() => page > 1 && setPage(page - 1)}*/}
      {/*            className='border rounded-full p-1 hover:text-azure hover:border-azure'>*/}
      {/*      <FiChevronLeft size={16}/>*/}
      {/*    </button>*/}
      {/*    <span className='font-semibold text-sm'>{page}</span>*/}
      {/*    <button onClick={() => !isLastPage && setPage(page + 1)}*/}
      {/*            className='border rounded-full p-1 hover:text-azure hover:border-azure'>*/}
      {/*      <FiChevronRight size={16}/>*/}
      {/*    </button>*/}
      {/*  </div>*/}
      {/*  <div className='flex items-center w-max gap-5'>*/}
      {/*    <label className='w-max text-sm'>{t("GLOBAL.PAGINATION_TITLE")}</label>*/}
      {/*    <select id="sizeOfPage" defaultValue={10} onChange={(e) => setSizeOfPage(e.target.value)}*/}
      {/*            className="appearance-none bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block w-14 p-2">*/}
      {/*      <option value={10}>10</option>*/}
      {/*      <option value={15}>15</option>*/}
      {/*      <option value={20}>20</option>*/}
      {/*    </select>*/}
      {/*  </div>*/}
      {/*</div>*/}

      {/*<Modal isOpen={isAddClientModalOpen} close={closeAddClientModal} title="Ajouter un Client">*/}
      {/*  <AddClientModal close={closeAddClientModal}/>*/}
      {/*</Modal>*/}
      <Modal isOpen={isDeleteClientModalOpen} close={closeDeleteClientModal} title="Delete partner" >
        <DeleteModal close={closeDeleteClientModal} clientId={modalData} fetchClients={fetchClients} />
      </Modal>
    </div>
  );
}

export default Partners;
