import api from "./api";


export const getDashboardStatistcis = async ()=>{
  try {
    const response = await api.get('/Dashboard/DashboardData');
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const getDashboardMonthlyData = async (id)=>{

  const params = {
    OpId: id
  }

  try {
    const response = await api.get('/Dashboard/DashboardMonthlyData', {params});
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}
